import { ModalController } from "@ionic/angular";
import { AppInjector } from "../app-injector/app-injector";

export class ModalHelper {
  private static _modalController: ModalController;

  private static get modalController(): ModalController {
    return (this._modalController)
      ? this._modalController
      : (this._modalController = AppInjector.prepare().get(ModalController));
  }

  public static async closeAll(): Promise<void> {
    let modal: HTMLIonModalElement;

    while ((modal = await this.modalController.getTop())) {
      await modal.dismiss();
    }
  }
}
