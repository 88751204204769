import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import {
  SettingsService,
  StorageService,
  ContextService,
  AppService,
  SpaceService,
  RouteService,
  LocationService
} from 'app/services';

import { KioskModeMock } from 'app/utils/kiosk';

import { Space, Route, Location, App } from 'app/interfaces/api/types';

import { Environment } from 'environments/environment';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;

@Component({
  selector: 'admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.scss'],
})

export class AdminConfigComponent implements OnInit {
  public kioskModeActive: boolean;
  public hideSettings: boolean;
  public stelenActive: boolean;
  public stelenInactivityRedirect: boolean;

  public appVersion: string = Environment.getAppVersion();
  public apiUrl: string = Environment.getServerUrl();
  public environment: string = (Environment.isProduction()) ? 'prod' : 'dev';
  public isLauncher: boolean = false;
  public didLoad: boolean = false;

  public spaces: Space[] = [];
  public routes: Route[] = [];
  public locations: Location[] = [];
  public apps: App[] = [];

  public stelenSpace: Space;
  public stelenRoute: Route;
  public stelenLocation: Location;

  public isBrowser: boolean = window.rdi.browserBuild;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly settings: SettingsService,
    private readonly storage: StorageService,
    private readonly context: ContextService,
    private readonly spaceService: SpaceService,
    private readonly routeService: RouteService,
    private readonly locationService: LocationService,
    private readonly navController: NavController,
    private readonly appService: AppService
  ) { }

  private get kioskMode(): any {
    return window.KioskMode || KioskModeMock;
  }

  async ngOnInit(): Promise<void> {
    this.didLoad = false;

    this.hideSettings = this.settings.getHideSettings();
    this.stelenActive = this.settings.getStelenActive();
    this.stelenInactivityRedirect = this.settings.getStelenInactivityRedirect();
    this.kioskModeActive = await this.kioskMode.isEnabled().catch(() => false);
    this.isLauncher = await this.kioskMode.isLauncher().catch(() => false);
    this.apps = (await this.appService.getAll()).sort((a: App, b: App) => b.id - a.id);

    await this.initStelen();

    this.didLoad = true;
  }

  public toggleKioskMode(enabled: any): void {
    this.settings.setKioskModeActive(enabled);
  }

  public switchLauncher(): void {
    this.kioskMode.switchLauncher().catch(console.warn);
  }

  public toggleHideSettings(enabled: any): void {
    this.settings.setHideSettings(enabled);
  }

  public toggleStelen(enabled: any): void {
    this.settings.setStelenActive(enabled);
    this.navController.navigateRoot((enabled) ? '/home' : '/');
  }

  private saveStelenState(): void {
    const stelenActive: boolean = (
      this.stelenActive &&
      !!this.stelenSpace &&
      !!this.stelenRoute &&
      !!this.stelenLocation
    );

    if (stelenActive) {
      this.context.setStelenSpace(this.stelenSpace);
      this.context.setStelenRoute(this.stelenRoute);
      this.context.setStelenLocation(this.stelenLocation);
    }

    const stelenInactivityRedirect: boolean = (stelenActive && this.stelenInactivityRedirect);

    this.settings.setStelenActive(stelenActive);
    this.settings.setStelenInactivityRedirect(stelenInactivityRedirect);
  }

  public dismiss(): void {
    this.saveStelenState();

    this.modalCtrl.dismiss();
  }

  public reload(): void {
    this.saveStelenState();

    window.location.reload();
  }

  public getStelenRoutes(): Route[] {
    if (this.stelenSpace) {
      return this.routes.filter((r: Route) => this.stelenSpace.routes.includes(r.id));
    } else {
      return [];
    }
  }

  public getStelenLocations(): Location[] {
    if (this.stelenRoute) {
      return this.locations.filter((l: Location) => this.stelenRoute.locations.includes(l.id));
    } else {
      return [];
    }
  }

  public stelenSpaceChange(): void {
    const routes: Route[] = this.getStelenRoutes();

    if (routes.length) {
      this.stelenRoute = routes[0];
    }
  }

  public stelenRouteChange(): void {
    const locations: Location[] = this.getStelenLocations();

    if (locations.length) {
      this.stelenLocation = locations[0];
    }
  }

  public compareFn(a: any, b: any): boolean {
    return (a && b && a.id == b.id);
  }

  public async setEnvironment(event: CustomEvent): Promise<void> {
    const { value } = event.detail;

    if (value !== this.environment) {
      if (value === 'prod') {
        Environment.initProduction();
      } else {
        this.storage.clear().then(() => Environment.initDevelopment());
      }
    }
  }

  private async initStelen(): Promise<void> {
    this.spaces = await this.spaceService.getAll();
    this.routes = await this.routeService.getAll();
    this.locations = await this.locationService.getAll();

    this.stelenSpace = await this.context.getStelenSpace();
    this.stelenRoute = await this.context.getStelenRoute();
    this.stelenLocation = await this.context.getStelenLocation();

    if (!this.stelenSpace && this.spaces) {
      this.stelenSpace = this.spaces[0];

      this.stelenSpaceChange();
      this.stelenRouteChange();
    }
  }
}
