import { Listener } from './listener';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { ToastController } from '@ionic/angular';
import { TranslatorService, StorageService } from 'app/services';

import * as jQuery from 'jquery';
import { ModalHelper } from 'app/classes/modal-helper/modal-helper';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;

export class VideoListener implements Listener {
  private $: JQueryStatic = jQuery;
  private toastController: ToastController;
  private translator: TranslatorService;
  private storageService: StorageService;

  constructor() {
    this.toastController = AppInjector.prepare().get(ToastController);
    this.translator = AppInjector.prepare().get(TranslatorService);
    this.storageService = AppInjector.prepare().get(StorageService);
  }

  public start(): void {
    this.$(document.body).on('click', '.video-outer', ({ currentTarget }) => {
      const $outer: JQuery<HTMLElement> = this.$(currentTarget);
      const video: HTMLVideoElement = $outer.find('video').get(0) as HTMLVideoElement;

      if (video) {
        video.onended = () => this.onVideoEnded(video);

        if (this.canPlayVideo(video)) {
          video.paused ? this.playVideo(video) : this.pauseVideo(video);
        } else {
          this.showToast('video_not_ready');
        }
      }
    });

    this.$(document.body).on('click', '.video-close-button', () => ModalHelper.closeAll());

    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        const $elementVideos = this.$(element).find('video:not([data-fixed-video])');
        const $videos = this.$(document.body)
          .find('video:not([data-fixed-video])')
          .add($elementVideos);

        $videos.attr('data-fixed-video', '');

        for (const video of $videos.toArray()) {
          await this.fixVideo(video as HTMLVideoElement);
        }
      } catch(err) {
        console.log(err);
      }
    });
  }

  private async fixVideo(video: HTMLVideoElement): Promise<void> {
    try {
      const $video: JQuery<HTMLVideoElement> = this.$(video);
      const src: string = $video.attr('src') || '';
      const url: string = await this.storageService.loadMediaSrc(src);

      $video.attr('src', url);
    } catch(err) {
      console.log(err);
    }

    video.oncanplay = () => video.setAttribute('data-ready', 'true');
    video.onerror = () => video.setAttribute('data-ready', 'true');
  }

  private onVideoEnded(video: HTMLVideoElement): void {
    const overlay: HTMLElement = this.getVideoOverlay(video);

    video.currentTime = 0;
    video.pause();

    if (overlay) {
      overlay.classList.add('visible');
    }
  }

  private playVideo(video: HTMLVideoElement): void {
    const overlay: HTMLElement = this.getVideoOverlay(video);

    if (overlay) {
      overlay.classList.remove('visible');
    }

    video.play();
  }

  private pauseVideo(video: HTMLVideoElement): void {
    const overlay: HTMLElement = this.getVideoOverlay(video);

    if (overlay) {
      overlay.classList.add('visible');
    }

    video.pause();
  }

  private canPlayVideo(video: HTMLVideoElement): boolean {
    return video.readyState === 4;
  }

  private getVideoOverlay(video: HTMLElement): HTMLElement {
    return video.parentElement.querySelector('.paused-overlay');
  }

  private async showToast(message: string, color: string = 'menucolor'): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message: this.translator.translate(message),
      showCloseButton: true,
      closeButtonText: this.translator.translate('close'),
      duration: 2000,
      color
    });

    await toast.present();

    return toast;
  }
}