import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

import { ContextService } from 'app/services';

import { Protagonist } from 'app/interfaces/api/types';

import { Subscription } from 'rxjs';

@Component({
  selector: 'protagonists-list',
  templateUrl: './protagonists-list.component.html',
  styleUrls: ['./protagonists-list.component.scss'],
})

export class ProtagonistsListComponent implements OnInit, OnDestroy {
  @Input() protagonists: Protagonist[] = [];

  public contextProtagonist: Protagonist;

  private subscription: Subscription = new Subscription();

  constructor(
    private popoverCtrl: PopoverController,
    private router: Router,
    private context: ContextService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.context.protagonistContextChange
        .subscribe((p: Protagonist) => this.contextProtagonist = p)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public displaySingleProtagonist(protagonist: Protagonist): void {
    this.router.navigateByUrl(`/protagonist/${protagonist.slug}`);

    this.popoverCtrl.dismiss();
  }

  public selectProtagonist(event: any, protagonist: Protagonist): void {
    if (event.target.nodeName != 'ION-BUTTON') {
      this.context.setActiveProtagonist(protagonist);
    }
  }
}
