import { Injectable } from '@angular/core';
import { Environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_TOKEN: string = Environment.getApiToken();

  constructor() {}

  public getToken(): string {
    return this.API_TOKEN;
  }
}
