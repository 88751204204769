import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { Story } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})

export class StoryService {
  constructor(private storage: StorageService) { }

  public getAll(): Promise<Story[]> {
    return this.storage.getStories();
  }

  public async getByID(id: number | string): Promise<Story | undefined> {
    return (await this.getAll()).find((s: Story) => s.id == id);
  }

  public async getBySlug(slug: string): Promise<Story | undefined> {
    return (await this.getAll()).find((s: Story) => s.slug == slug);
  }
}
