import { ModalController } from '@ionic/angular';
import { ImageViewerComponent } from 'app/components/image-viewer/image-viewer.component';
import { Attachment } from 'app/interfaces/api/types';
import { AttachmentService } from 'app/services';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { Listener } from './listener';

import * as jQuery from 'jquery';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;

export class ImageViewerListener implements Listener {
  private $: JQueryStatic = jQuery;
  private attachmentService: AttachmentService;
  private modalController: ModalController;

  constructor() {
    this.attachmentService = AppInjector.prepare().get(AttachmentService);
    this.modalController = AppInjector.prepare().get(ModalController);
  }

  public start(): void {
    this.$(document.body).on('click', 'img:not([data-disable-lightbox])', ({ currentTarget }) => {
      const $image: JQuery<HTMLElement> = this.$(currentTarget);

      this.openLightbox($image);
    });

    this.$(document.body).on('click', 'ion-fab-button.image-viewer-trigger', ({ currentTarget }) => {
      const $image: JQuery<HTMLElement> = this.$(currentTarget)
        .parents('div.image-container')
        .find('img');

      this.openLightbox($image);
    });

    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        await this.createLightboxButton(element);
        await this.createLightboxButton(document.body);
      } catch(err) {
        console.log(err);
      }
    });
  }

  private async openLightbox($image: JQuery<HTMLElement>): Promise<void> {
    const src: string = $image.attr('src');
    const alt: string = $image.data('alternative-lightbox');
    const sub: string = $image.data('subtext');
    const url: string = (alt) ? alt : src;
    const filename: string = url.split('/').pop();
    const attachment: Attachment = await this.attachmentService.getByFilename(filename);

    if (attachment) {
      attachment.subtext = sub || '';

      const modal: HTMLIonModalElement = await this.modalController.create({
        component: ImageViewerComponent,
        componentProps: {
          attachments: [attachment]
        },
        cssClass: 'image-full-screen transparent-modal'
      });

      await modal.present();

      document.dispatchEvent(new CustomEvent('rdi::openLightbox', {
        detail: { url }
      }));
    }
  }

  private async createLightboxButton(root: HTMLElement): Promise<void> {
    this.$(root)
      .find('div.image-container > img:not([data-disable-lightbox])')
      .map((_, element: HTMLElement) => this.$(element).parent())
      .filter((_, $parent: JQuery<HTMLElement>) => $parent.find('ion-fab').length === 0)
      .each((_, $parent: JQuery<HTMLElement>) => {
        this.$(`
          <ion-fab style="right: 0px;">
            <ion-fab-button size="small" color="menucolor" class="image-viewer-trigger">
              <img class="svg" style="height: 100%; width: 100%;" src="/assets/img/zoom_icon.svg" />
            </ion-fab-button>
          </ion-fab>
        `).appendTo($parent);
      });
  }
}
