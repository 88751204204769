export const rdiBounds = {
  northeast: {
    lat: 51.68452351492408,
    lng: 7.786994406199142,
  },
  southwest: {
    lat: 51.311800687271464,
    lng: 6.549022369384829,
  },
};

export enum LocationType {
  STATION = 'station',
  SIDE_KICK = 'abstecher',
  HIDDEN_PLACE = 'hidden-place',
}

export const alphabeth = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
