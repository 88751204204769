import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DomHelper } from 'app/classes/dom-helper/dom-helper';

import { Location } from 'app/interfaces/api/types';

@Component({
  selector: 'storyteller-overlay',
  templateUrl: './storyteller-overlay.component.html',
  styleUrls: ['./storyteller-overlay.component.scss'],
})
export class StorytellerOverlayComponent implements OnInit, AfterViewInit {
  @ViewChild('content', { static: false, read: ElementRef })
  private content: ElementRef;

  @Input() location: Location;
  @Input() onProceed: Function;

  public didLoad: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    DomHelper.whenReady(this.content.nativeElement).then(() => this.didLoad = true);
  }

  public proceed(): void {
    this.onProceed && this.onProceed();
  }
}
