import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Environment } from './environments/environment';

import { AppInjector } from 'app/classes/app-injector/app-injector';

if (Environment.isProduction()) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((appRef: { injector: Injector }) => AppInjector.prepare(appRef.injector))
  .catch(err => console.log(err));
