import { Injectable } from '@angular/core';
import { Location, Protagonist } from 'app/interfaces/api/types';
import { StorageService } from 'app/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private storage: StorageService) {}

  public getAll(): Promise<Location[]> {
    return this.storage.getLocations();
  }

  public async getByID(id: number | string): Promise<Location> {
    return (await this.getAll()).find((l: Location) => l.id == id);
  }

  public async getByIDs(ids: number[]): Promise<Location[]> {
    return await Promise.all(ids.map((id: number) => this.getByID(id)));
  }

  public async getBySlug(slug: string): Promise<Location | undefined> {
    return (await this.getAll()).find((l: Location) => l.slug == slug);
  }

  /**
   * Helper functions
   */
  public async getProtagonists(location: Location): Promise<Protagonist[]> {
    const protagonistIds = [];
    for (const sId of location.stories) {
      const story = (await this.storage.getStories()).find(s => s.id === sId);
      protagonistIds.push(story.protagonist);
    }

    return (await this.storage.getProtagonists()).filter((p: Protagonist) =>
      protagonistIds.includes(p.id),
    );
  }
}
