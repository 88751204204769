import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { Term } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})

export class TermService {
  constructor(private storage: StorageService) { }

  public getAll(): Promise<Term[]> {
    return this.storage.getTerms();
  }

  public async getByID(id: number | string): Promise<Term | undefined> {
    return (await this.getAll()).find((t: Term) => t.id == id);
  }
}
