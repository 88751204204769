import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Term } from 'app/interfaces/api/types';
import { StorageService, TermService } from 'app/services';

import { Environment } from 'environments/environment';

import * as jQuery from 'jquery';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  private readonly API_UPLOADS: string = Environment.getServerUploadUrl();

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly termService: TermService,
    private readonly storage: StorageService,
  ) {}

  async transform(html, args: string = '', exclude: string = '') {
    if (!html) return html;

    const options: string[] = args.split(',');

    if (options.includes('glossary')) {
      html = await this.glossaryLink(html, exclude);
    }

    if (options.includes('url') || options.includes('html')) {
      html = await this.attachmentUrls(html);
    }

    if (options.includes('html')) {
      html = this.sanitizer.bypassSecurityTrustHtml(html);
    }

    if (this.isUrl(html)) {
      html = this.sanitizer.bypassSecurityTrustUrl(html);
    }

    return html;
  }

  private async attachmentUrls(input: string): Promise<string> {
    await this.storage.loadOfflineMedia();

    if (this.isUrl(input)) {
      return this.storage.convertMediaSrc(input);
    } else {
      const $html = jQuery(`<div>${input}</div>`);

      $html.find(`img[src*="${this.API_UPLOADS}"]`).each((_, e) => {
        const src: string = e.getAttribute('src');

        e.setAttribute('src', this.storage.convertMediaSrc(src));
      });

      return $html.html();
    }
  }

  private async glossaryLink(html: string, exclude: string = ''): Promise<string> {
    const $elements = jQuery(`<div>${html}</div>`);

    (await this.termService.getAll()).forEach((term: Term) => {
      if (exclude && term.name == exclude) {
        return;
      }

      const value: string = `<a class="glossary-term" data-term-id="${term.id}">${term.name}</a>`;
      const regexp: RegExp = new RegExp(`\\b${term.name}\\b(?=([^"\\\\]*(\\\\.|"([^"\\\\]*\\\\.)*[^"\\\\]*"))*[^"]*$)`, 'g');

      $elements.find('p, blockquote, span').each((_, e) => {
        const $this = jQuery(e);

        $this.html($this.html().replace(regexp, value));

        return !$this.html().match(regexp) as any;
      });
    });

    $elements.find('.glossary-term').each(function(i) {
      const $parents: JQuery<HTMLElement> = jQuery(this).parents('[data-ignore-glossary]');
      const ignore: boolean = jQuery(this).is('[data-ignore-glossary]');

      if ($parents.length || ignore) {
        jQuery(this).replaceWith(jQuery(this).html());
      }
    });

    return $elements.html();
  }

  private isUrl(url: string): boolean {
    try {
      new URL(url);

      return true;
    } catch(err) {
      return false;
    }
  }
}
