import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { App } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private storage: StorageService) { }

  public getAll(): Promise<App[]> {
    return this.storage.getApps();
  }
}
