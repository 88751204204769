import { Pipe, PipeTransform } from '@angular/core';
import { TranslatorService } from './translator.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(private translator: TranslatorService) {}
  transform(value: string, args: any[]): any {
    return (value) ? this.translator.translate(value) : '';
  }
}
