import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { Page } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})

export class PageService {
  constructor(private storage: StorageService) { }

  public getAll(): Promise<Page[]> {
    return this.storage.getPages();
  }

  public getHomepage(): Promise<Page | undefined> {
    return this.storage.getHomePage();
  }

  public async getByID(id: number | string): Promise<Page | undefined> {
    return (await this.getAll()).find((p: Page) => p.id == id);
  }

  public async getBySlug(slug: string): Promise<Page | undefined> {
    return (await this.getAll()).find((p: Page) => p.slug == slug);
  }
}
