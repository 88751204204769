import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Attachment } from 'app/interfaces/api/types';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() attachments: Attachment[] = [];

  public title: string = '';

  slideOpts = {
    centeredSlides: 'true'
  };

  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    if (this.attachments.length) {
      this.title = this.attachments[0].subtext;
    }
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  public async handleSlideChange(e: any): Promise<void> {
    const index: number = await e.target.getActiveIndex();

    this.title = this.attachments[index].subtext;
  }
}
