import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ProtagonistService } from 'app/services/protagonist/protagonist.service';
import { RouteService } from 'app/services/route/route.service';

import { SpaceService } from 'app/services/space/space.service';
import { LocationService } from 'app/services/location/location.service';

import { Protagonist, Route, Space, Location } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})

export class ContextService {
  public readonly protagonistContextChange: BehaviorSubject<Protagonist> = new BehaviorSubject(undefined);
  public readonly routeContextChange: BehaviorSubject<Route> = new BehaviorSubject(undefined);
  public readonly stelenSpaceContextChange: Subject<Space> = new Subject();
  public readonly stelenRouteContextChange: Subject<Route> = new Subject();
  public readonly stelenLocationContextChange: Subject<Location> = new Subject();

  constructor(
    private readonly protagonistService: ProtagonistService,
    private readonly routeService: RouteService,
    private readonly spaceService: SpaceService,
    private readonly locationService: LocationService
  ) { }

  public getActiveProtagonist(): Promise<Protagonist> {
    const id: string = localStorage.getItem('context_manager_active_protagonist_id');

    return this.protagonistService.getByID(id);
  }

  public setActiveProtagonist(protagonist: Protagonist): void {
    localStorage.setItem('context_manager_active_protagonist_id', String(protagonist.id));

    this.protagonistContextChange.next(protagonist);
  }

  public getActiveRoute(): Promise<Route> {
    const id: string = localStorage.getItem('context_manager_active_route_id');

    return this.routeService.getByID(id);
  }

  public setActiveRoute(route: Route): void {
    localStorage.setItem('context_manager_active_route_id', String(route.id));

    this.routeContextChange.next(route);
  }

  /**
   * Stelen feature
   */
  public getStelenSpace(): Promise<Space> {
    const slug: string = localStorage.getItem('context_manager_stelen_space_slug');

    return this.spaceService.getBySlug(slug);
  }

  public setStelenSpace(space: Space): void {
    localStorage.setItem('context_manager_stelen_space_slug', String(space.slug));

    this.stelenSpaceContextChange.next(space);
  }

  public getStelenRoute(): Promise<Route> {
    const slug: string = localStorage.getItem('context_manager_stelen_route_slug');

    return this.routeService.getBySlug(slug);
  }

  public setStelenRoute(route: Route): void {
    localStorage.setItem('context_manager_stelen_route_slug', String(route.slug));

    this.stelenRouteContextChange.next(route);
  }

  public getStelenLocation(): Promise<Location> {
    const slug: string = localStorage.getItem('context_manager_stelen_location_slug');

    return this.locationService.getBySlug(slug);
  }

  public setStelenLocation(location: Location) {
    localStorage.setItem('context_manager_stelen_location_slug', String(location.slug));

    this.stelenLocationContextChange.next(location);
  }
}
