import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClientModule } from '@angular/common/http';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { ComponentsModule } from 'app/components/components.module';
import { SingleTermPageModule } from 'app/pages/single-term/single-term.module';
import { PipesModule } from './pipes.module';
import { Facebook } from '@ionic-native/facebook/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__rdi_db',
      driverOrder: ['indexeddb', 'websql', 'sqlite', 'localstorage'],
    }),
    AppRoutingModule,
    PipesModule,
    ComponentsModule,
    SingleTermPageModule,
    HttpClientModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    HTTP,
    WebView,
    Zip,
    ScreenOrientation,
    FirebaseAnalytics,
    Facebook,
    InAppBrowser
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
