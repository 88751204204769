import { Router, NavigationStart } from '@angular/router';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { Listener } from './listener';
import { filter } from 'rxjs/operators';
import { IonContent } from '@ionic/angular';

import * as jQuery from 'jquery';
import 'jquery-touchswipe';
import { RdiWindow } from 'app/interfaces/rdi';

type JQuerySwipable = JQuery<HTMLElement> & { swipe: any };

declare var window: RdiWindow;

class ImageSlider {
  constructor(private $root: JQuerySwipable) {
    this.$root.addClass('active');

    this.$root.find('.dot').on('click', (e) => {
      const index: number = Number(jQuery(e.currentTarget).attr('data-index'));

      this.activateIndex(index);
    });

    this.$root.find('ion-button.next-slide').on('click', () => this.next());
    this.$root.find('ion-button.previous-slide').on('click', () => this.previous());
    this.$root.swipe({
      swipeLeft: () => this.next(),
      swipeRight: () => this.previous()
    });
  }

  private next(): void {
    if (this.hasNext) {
      this.activateIndex(this.activeIndex + 1);
    }
  }

  private previous(): void {
    if (this.hasPrevious) {
      this.activateIndex(this.activeIndex - 1);
    }
  }

  private activateIndex(index: number): void {
    if (index !== this.activeIndex) {
      this.$root.find('.rdi-slide').removeClass('active');
      this.$root.find('.rdi-slide').eq(index).addClass('active');

      this.$root.find('.dot').removeClass('active');
      this.$root.find('.dot').eq(index).addClass('active');

      this.$root.find('ion-button.next-slide').attr('disabled', () => this.hasNext ? null : '');
      this.$root.find('ion-button.previous-slide').attr('disabled', () => this.hasPrevious ? null : '');
    }
  }

  private get activeIndex(): number {
    return this.$root.find('.rdi-slide').toArray().findIndex((e) => e.classList.contains('active'));
  }

  private get hasNext(): boolean {
    return this.hasIndex(this.activeIndex + 1);
  }

  private get hasPrevious(): boolean {
    return this.hasIndex(this.activeIndex - 1);
  }

  private hasIndex(index: number): boolean {
    return this.$root.find('.rdi-slide').length > index && index >= 0;
  }
}

export class ImageSlidesListener implements Listener {
  private $: JQueryStatic = jQuery;
  private router: Router;
  private slider: ImageSlider[] = [];

  constructor() {
    this.router = AppInjector.prepare().get(Router);
  }

  public start(): void {
    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        await this.createImageSlider(element);
        await this.createImageSlider(document.body);
      } catch(err) {
        console.log(err);
      }
    });

    this.createImageSlider(document.body);

    this.router.events
      .pipe(filter((e: any) => e instanceof NavigationStart))
      .subscribe(() => this.clearImageSlider());
  }

  private async createImageSlider(root: HTMLElement): Promise<void> {
    const ionContents: unknown[] = this.$('ion-content').toArray();

    for (const ionContent of ionContents) {
      const id: string = 'image-slider-scroll-snap-style';
      const root: ShadowRoot = (ionContent as HTMLElement).shadowRoot;
      const exists: boolean = !!root.getElementById(id);
      const scrollElement: HTMLElement = await (ionContent as IonContent).getScrollElement();

      if (!exists && scrollElement) {
        const style: HTMLStyleElement = document.createElement('style');

        style.id = id;
        style.innerHTML = `
          .scroll-snap-type-y {
            scroll-snap-type: y proximity;
          }
        `;

        root.prepend(style);
        scrollElement.classList.add('scroll-snap-type-y');
      }
    }

    this.$(root)
      .find('.rdi-slides-container:not(.active)')
      .map((_, e: HTMLElement) => this.$(e))
      .each((_, $e: JQuery<HTMLElement>) => {
        this.slider.push(new ImageSlider($e as JQuerySwipable));
      });
  }

  private clearImageSlider(): void {
    this.slider.length = 0;
  }
}
