import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Environment } from 'environments/environment';

@Component({
  selector: 'app-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
})

export class AppBannerComponent implements OnInit {
  private openAppLink: string = Environment.getServerUrl() + '/wp-json/rdi/v1/app/open';

  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void {}

  public useApp(): void {
    window.open(this.openAppLink, '_system');
  }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
