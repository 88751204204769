import * as jQuery from 'jquery';

import { SingleTermPage } from 'app/pages/single-term/single-term.page';
import { ModalController } from '@ionic/angular';
import { TermService } from 'app/services';

import { AppInjector } from 'app/classes/app-injector/app-injector';

import { Term } from 'app/interfaces/api/types';

import { Listener } from './listener';
import { ModalHelper } from 'app/classes/modal-helper/modal-helper';

export class GlossaryLinkListener implements Listener {
  private $: JQueryStatic = jQuery;

  private modalController: ModalController;
  private termService: TermService;

  constructor() {
    this.modalController = AppInjector.prepare().get(ModalController);
    this.termService = AppInjector.prepare().get(TermService);
  }

  public start(): void {
    this.$(document.body).on('click', '[data-term-id]', async ({ currentTarget }) => {
      const $element: JQuery<HTMLElement> = this.$(currentTarget);
      const id: string = $element.data('term-id');
      const term: Term = await this.termService.getByID(id);

      if (term) {
        await this.openTerm(term);
      }
    });
  }

  private async openTerm(term: Term): Promise<void> {
    await ModalHelper.closeAll();

    const modal: HTMLIonModalElement = await this.modalController.create({
      component: SingleTermPage,
      componentProps: { term },
      // cssClass: 'image-full-screen transparent-modal',
    });

    await modal.present();
  }
}