import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DomHelper } from 'app/classes/dom-helper/dom-helper';

import { Term } from 'app/interfaces/api/types';

@Component({
  selector: 'app-single-term',
  templateUrl: './single-term.page.html',
  styleUrls: ['./single-term.page.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SingleTermPage implements OnInit {
  @ViewChild('content', { static: false, read: ElementRef })
  private content: ElementRef;

  public term: Term;
  public didLoad: boolean = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly navParams: NavParams
  ) { }

  ngOnInit(): void { }

  ionViewWillEnter(): void {
    this.reset();
  }

  ionViewDidEnter(): void {
    this.term = this.navParams.data.term;

    DomHelper.whenReady(this.content.nativeElement).then(() => this.didLoad = true);
  }

  public goBack(): void {
    this.modalCtrl.dismiss();
  }

  private reset(): void {
    this.didLoad = false;
    this.term = null;
  }
}
