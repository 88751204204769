import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { Attachment } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})

export class AttachmentService {
  constructor(private storage: StorageService) { }

  public getAll(): Promise<Attachment[]> {
    return this.storage.getAttachments();
  }

  public async getByID(id: number | string): Promise<Attachment | undefined> {
    return (await this.getAll()).find((a: Attachment) => a.id == id);
  }

  public async getBySlug(slug: string): Promise<Attachment | undefined> {
    return (await this.getAll()).find((a: Attachment) => a.slug == slug);
  }

  /**
   * Helper functions
   */
  public async getByURL(url: string): Promise<Attachment | undefined> {
    return (await this.getAll()).find((a: Attachment) => a.url == url);
  }

  public async getByFilename(filename: string): Promise<Attachment | undefined> {
    return (await this.getAll())
      .find((a: Attachment) => a.url.split('/').pop() == filename);
  }
}
