import { Router, NavigationStart } from '@angular/router';
import { SettingsService, TranslatorService } from 'app/services';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { filter, skip } from 'rxjs/operators';

import { Listener } from './listener';

import * as jQuery from 'jquery';


export class FirebaseAnalyticsListener implements Listener {
  private $: JQueryStatic = jQuery;

  private router: Router;
  private settingsService: SettingsService;
  private translatorService: TranslatorService;
  private firebaseAnalytics: FirebaseAnalytics;

  constructor() {
    this.router = AppInjector.prepare().get(Router);
    this.settingsService = AppInjector.prepare().get(SettingsService);
    this.translatorService = AppInjector.prepare().get(TranslatorService);
    this.firebaseAnalytics = AppInjector.prepare().get(FirebaseAnalytics);
  }

  public async start(): Promise<void> {
    this.router.events
      .pipe(filter((e: any) => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => this.logRouterNavigation(e));

    this.settingsService.offlineContentChange
      .pipe(skip(1))
      .subscribe((enabled: boolean) => this.logOfflineContent(enabled));

    this.settingsService.offlineMapChange
      .pipe(skip(1))
      .subscribe((enabled: boolean) => this.logOfflineMap(enabled));

    this.translatorService.languageChange
      .subscribe(() => this.logLanguage(this.translatorService.getLanguage()));

    jQuery(document).on('rdi::startRoute', (e: any) => this.logRouteStart(e.detail.slug));
    jQuery(document).on('rdi::openStory', (e: any) => this.logStoryOpen(e.detail.slug));
    jQuery(document).on('rdi::playAudio', (e: any) => this.logAudioPlay(e.detail.url));
    jQuery(document).on('rdi::openLightbox', (e: any) => this.logLightboxOpen(e.detail.url));
    jQuery(document).on('rdi::downloadMedia', (e: any) => this.logMediaDownload(e.detail.slug));
    jQuery(document).on('rdi::downloadMap', (e: any) => this.logMapDownload(e.detail.slug));
  }

  private logRouterNavigation(e: NavigationStart): void {
    const { url } = e;

    this.log('router_navigate', { url }).catch(console.warn);
  }

  private logAudioPlay(url: string): void {
    this.log('audio_play', { url }).catch(console.warn);
  }

  private logOfflineContent(enabled: boolean): void {
    if (enabled) {
      this.log('offline_content_enabled', { }).catch(console.warn);
    }
  }

  private logOfflineMap(enabled: boolean): void {
    if (enabled) {
      this.log('offline_map_enabled', { }).catch(console.warn);
    }
  }

  private logLanguage(lang: string): void {
    this.log('language_change', { lang }).catch(console.warn);
  }

  private logRouteStart(slug: string): void {
    this.log('start_route', { slug }).catch(console.warn);
  }

  private logStoryOpen(slug: string): void {
    this.log('open_story', { slug }).catch(console.warn);
  }

  private logLightboxOpen(url: string): void {
    this.log('open_lightbox', { url }).catch(console.warn);
  }

  private logMediaDownload(slug: string): void {
    this.log('download_media', { slug }).catch(console.warn);
  }

  private logMapDownload(slug: string): void {
    this.log('download_map', { slug }).catch(console.warn);
  }

  private async log(name: string, data: any): Promise<any> {
    try {
      await this.firebaseAnalytics.logEvent(name, data);
    } catch(err) {
      console.log(err);
    }
  }
}