import { Component, OnInit, Input } from '@angular/core';

import { Location } from 'app/interfaces/api/types';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent implements OnInit {
  @Input() locations: Location[] = [];
  @Input() currentLocation: Location;
  @Input() onOpenLocation: Function;

  constructor() { }

  ngOnInit(): void {}

  public openLocation(location: Location): void {
    this.onOpenLocation && this.onOpenLocation(location);
  }
}
