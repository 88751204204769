import { Component, OnInit, Input } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit {
  private _visible: boolean = false;

  public showCancelButton: boolean = false;

  constructor(private readonly statusBar: StatusBar) { }

  @Input()
  public autoStyleStatusBar: boolean = true;

  @Input()
  public set visible(visible: boolean) {
    this._visible = visible;
    this.showCancelButton = false;

    if (visible) {
      setTimeout(() => this.showCancelButton = this._visible, 1000 * 5);
    }

    this.styleStatusBar();
  }

  public get visible(): boolean {
    return this._visible;
  }

  ngOnInit(): void { }

  public cancel(): void {
    this._visible = false;

    this.styleStatusBar();
  }

  private styleStatusBar(): void {
    if (this.autoStyleStatusBar) {
      const statusBarColor: string = this._visible ? '#002c42' : '#ffe500';

      this.statusBar.backgroundColorByHexString(statusBarColor);
    }
  }
}
