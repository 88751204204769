import { AppInjector } from 'app/classes/app-injector/app-injector';
import { Zip } from '@ionic-native/zip/ngx';

let zip: Zip;

export const unzipFile = async (path: string, destination: string): Promise<boolean> => {
  zip = zip || AppInjector.prepare().get(Zip);

  try {
    const result: number = await zip.unzip(path, destination);

    return (result === 0);
  } catch(err) {
    console.log(err);

    return false;
  }
};