export default {
  de: {
    start: 'Start',
    home: 'Home',
    start_route: 'Route starten',
    go_to_story: 'Zur Geschichte',
    more_label: 'Weiter',
    learn_more: 'Mehr erfahren',
    next: 'Weiter',
    previous: 'Zurück',
    content: 'Inhalt',
    media: 'Medien',
    navigate: 'Navigieren',

    route: 'Route',
    location: 'Standort',

    /* Languages */
    language: 'Sprache',

    german: 'Deutsch',
    english: 'Englisch',

    de: 'Deutsch',
    en: 'Englisch',

    /* Settings related */
    settings: 'Einstellungen',
    offline_content: 'Offline Inhalte',
    enable_offline_content: 'Inhalt offline verfügbar machen',
    clear_local_data: 'Lokale Daten löschen',
    local_data_cleared: 'Lokale Daten wurden gelöscht',
    map_cleared: 'Karte wurde gelöscht',
    failed_to_clear_local_data: 'Lokale Daten konnte nicht gelöscht werden',
    failed_to_clear_map: 'Karte konnte nicht gelöscht werden',
    download_content: 'Inhalte herunterladen',
    download_media: 'Medien herunterladen',
    update_media: 'Medien aktualisieren',
    download_map: 'Karte herunterladen',
    update_content: 'Inhalte aktualisieren',
    downloading_media: 'Medien werden heruntergeladen',
    downloading_map: 'Karte wird heruntergeladen',
    downloading_content: 'Inhalte werden heruntergeladen',
    updating_content: 'Inhalte werden aktualisiert',
    failed_to_download_map: 'Fehler beim Herunterladen der Karte',
    failed_to_download_media: 'Fehler beim Herunterladen der Medien',
    failed_to_download_content: 'Fehler beim Herunterladen der Inhalte',
    failed_to_update_content: 'Fehler beim Aktualisieren der Inhalte',
    unzipping_map_tiles: 'Karte wird entpackt',
    failed_downloads: 'Fehlerhafte Downloads',
    successful_downloads: 'Erfolgreiche Downloads',
    something_went_wrong: 'Es ist ein Fehler aufgetreten',
    clearing_map: 'Lösche Karte',
    clearing_content: 'Lösche Inhalte',

    map: 'Karte',
    enable_offline_map: 'Karte offline verfügbar machen',
    use_offline_map: 'Offline Karte benutzen',
    __use_offline_map_note: 'Heruntergeladene Kartenkacheln werden genutzt',
    use_offline_content: 'Offline Inhalte benutzen',
    __use_offline_content_note: 'Die neusten Inhalte werden heruntergeladen und offline genutzt',
    content_successfully_downloaded: 'Inhalte wurden erfolgreich heruntergeladen',
    preparing_offline_content: 'Offline Inhalte werden vorbereitet',
    clear_map_data: 'Lokale Karte löschen',
    downloaded: 'Heruntergeladen',
    unzipped: 'Entpackt',
    cancel: 'Abbrechen',
    skip_loading: 'Laden überspringen',
    saving: 'Speichern...',
    hint: 'Hinweis',
    settings_hint_download_size: 'Aufgrund der hohen Datenmenge kann es zu langen Wartezeiten kommen. Wir empfehlen eine stabile Verbindung in einem WLAN.',
    offline_usage: 'Offline-Nutzung',
    offline_usage_text: 'Wenn Sie die App im Offline-Modus nutzen wollen, müssen Sie unter den App-Einstellungen jeweils die Inhalte und Karte offline verfügbar machen.',
    no_thanks: 'Nein Danke',
    close: 'Schließen',

    switch_protagonist: 'Wechseln Sie hier den Protagonisten',
    back_to_the_selection: 'Zurück zur Auswahl',
    back: 'Zurück',
    profile: 'Profil',
    born: 'geb.',
    enter_pin: 'PIN eingeben',

    we_can_do_better: 'Das geht noch besser',
    install_the_app_on_your_device: 'Mit der Perspektivwechsel App können Sie unser Angebot noch besser nutzen.',
    use_app: 'App benutzen',
    part: 'Teil',
    this_may_take_a_while: 'Das kann eine Weile dauern',
    could_not_download_map_part: 'Kartenteil konnte nicht heruntergeladen werden',
    map_sections: 'Kartenbereiche',
    spaces: 'Erlebnisräume',
    pages: 'Seiten',
    marginal_areas: 'Randbereiche',
    this_will_delete_all_local_data: 'Dies wird alle lokalen Daten löschen',
    are_you_sure: 'Sind Sie sicher',
    yes: 'Ja',
    download_successful: 'Download erfolgreich',
    video_not_ready: 'Video ist noch nicht bereit',
    audio_not_ready: 'Audio ist noch nicht bereit',
    audio_could_not_be_loaded: 'Audio konnte nicht geladen werden',
  },
  en: {
    start: 'Start',
    home: 'Home',
    start_route: 'Start route',
    go_to_story: 'To the story',
    more_label: 'More',
    learn_more: 'Learn more',
    next: 'Next',
    previous: 'Previous',
    content: 'Content',
    media: 'Media',
    navigate: 'Navigate',

    route: 'Route',
    location: 'Location',

    /* Languages */
    language: 'Language',

    german: 'German',
    english: 'English',

    de: 'German',
    en: 'English',

    /* Settings related */
    settings: 'Settings',
    offline_content: 'Offline content',
    enable_offline_content: 'Make content available offline',
    clear_local_data: 'Clear local data',
    local_data_cleared: 'Local data was cleared',
    map_cleared: 'Map was cleared',
    failed_to_clear_local_data: 'Failed to clear local data',
    failed_to_clear_map: 'Failed to clear map data',
    download_content: 'Download content',
    download_media: 'Download media',
    update_media: 'Update media',
    download_map: 'Download map',
    update_content: 'Update content',
    downloading_media: 'Downloading media',
    downloading_map: 'Downloading map',
    downloading_content: 'Downloading content',
    updating_content: 'Updating content',
    failed_to_download_map: 'Failed to download map',
    failed_to_download_media: 'Failed to download media',
    failed_to_download_content: 'Failed to download content',
    failed_to_update_content: 'Failed to update content',
    unzipping_map_tiles: 'Unzipping map tiles',
    failed_downloads: 'Failed downloads',
    successful_downloads: 'Successful downloads',
    something_went_wrong: 'Something went wrong',
    clearing_map: 'Clearing map',
    clearing_content: 'Clearing content',

    map: 'Map',
    enable_offline_map: 'Make map available offline',
    use_offline_map: 'Use offline map',
    __use_offline_map_note: 'Downloaded map tiles will be used',
    use_offline_content: 'Use offline content',
    __use_offline_content_note: 'Content will be downloaded und used offline',
    content_successfully_downloaded: 'Content was successfully downloaded',
    preparing_offline_content: 'Preparing offline content',
    clear_map_data: 'Clear map data',
    downloaded: 'Downloaded',
    unzipped: 'Unzipped',
    cancel: 'Cancel',
    skip_loading: 'Skip loading',
    saving: 'Saving...',
    hint: 'Hint',
    settings_hint_download_size: 'Due to the high amount of data, long waiting times can occur. We recommend a stable WLAN connection.',
    offline_usage: 'Offline Usage',
    offline_usage_text: 'If you want to use the app offline, you need to enable "Make content available offline" and "Make map available offline" in the app menu under settings.',
    no_thanks: 'No thanks',
    close: 'Close',

    switch_protagonist: 'Change the protagonist',
    back_to_the_selection: 'Back to the selection',
    back: 'Back',
    profile: 'Profile',
    born: 'DOB',
    enter_pin: 'Enter PIN',

    we_can_do_better: 'We can do better',
    install_the_app_on_your_device: 'Install the Perspektivwechsel app on your device to get the best out of our services.',
    use_app: 'Use the app',
    part: 'Part',
    this_may_take_a_while: 'This may take a while',
    could_not_download_map_part: 'Could not download map part',
    map_sections: 'Map sections',
    spaces: 'Spaces',
    pages: 'Pages',
    marginal_areas: 'Marginal areas',
    this_will_delete_all_local_data: 'This will delete all local data',
    are_you_sure: 'Are you sure',
    yes: 'Yes',
    download_successful: 'Download successful',
    video_not_ready: 'Video is not yet ready',
    audio_not_ready: 'Audio is not yet ready',
    audio_could_not_be_loaded: 'Audio could not be loaded',
  },
};
