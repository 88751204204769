import { arrayFlat } from 'app/utils';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;
export class DomHelper {
  public static readonly MAX_INTERVAL: number = 10; // 500ms interval -> 5 seconds

  public static async whenReady(root: HTMLElement): Promise<void> {
    await window.rdi.ready();

    await Promise.all([
      this.waitForElements(root),
      this.waitForImages(root),
      this.waitForVideos(root)
    ]);

    await this.waitForListeners(root);
  }

  /**
   * Run all listeners and wait for them to say they're
   * done by listening to a unique event name.
   * If this is confusing to you, ask me about it.
   */
  public static async waitForListeners(element: HTMLElement): Promise<void> {
    await Promise.all(window.rdi.listeners.map((l) => l(element)));
  }

  public static waitForVideos(root: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      const videos: HTMLElement[] = this.getVideoElements(root);
      let intervalCount: number = 0;

      const interval = setInterval(() => {
        const completed: boolean = videos.every((v: HTMLVideoElement) => v.getAttribute('data-ready') === 'true');

        if (completed || ++intervalCount > this.MAX_INTERVAL) {
          clearInterval(interval);

          resolve();
        }
      }, 500);
    });
  }

  public static waitForImages(root: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      const images: HTMLElement[] = this.getImageElements(root);
      let intervalCount: number = 0;

      const interval = setInterval(() => {
        const completed: boolean = images.every((img: HTMLImageElement) => img.complete);

        if (completed || ++intervalCount > this.MAX_INTERVAL) {
          clearInterval(interval);

          resolve();
        }
      }, 500);
    });
  }

  public static waitForElements(root: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      let intervalCount: number = 0;
      let count: number = -1;

      const interval = setInterval(() => {
        const elements: HTMLElement[] = this.getLoadingElements(root);

        if (count === elements.length || ++intervalCount > this.MAX_INTERVAL) {
          clearInterval(interval);

          resolve();
        } else {
          count = elements.length;
        }
      }, 500);
    });
  }

  public static getLoadingElements(root: HTMLElement): HTMLElement[] {
    return arrayFlat([
      this.getImageElements(root),
      this.getIonImageElements(root),
      this.getAudioElements(root),
      this.getVideoElements(root)
    ]);
  }

  public static getVideoElements(root: HTMLElement): HTMLElement[] {
    return Array.from(root.querySelectorAll('video'));
  }

  public static getImageElements(root: HTMLElement): HTMLElement[] {
    return Array.from(root.querySelectorAll('img'));
  }

  public static getIonImageElements(root: HTMLElement): HTMLElement[] {
    return Array.from(root.querySelectorAll('ion-img'));
  }

  public static getAudioElements(root: HTMLElement): HTMLElement[] {
    return Array.from(root.querySelectorAll('audio'));
  }
}
