import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PassLockComponent } from 'app/components/pass-lock/pass-lock.component';
import { AdminConfigComponent } from 'app/components/admin-config/admin-config.component';

@Component({
  selector: 'app-logo-footer',
  templateUrl: './logo-footer.component.html',
  styleUrls: ['./logo-footer.component.scss'],
})
export class LogoFooterComponent implements OnInit {
  private clicks: number = 0;
  private timeout: number;

  constructor(private modalController: ModalController) { }

  ngOnInit(): void {}

  public onClick(): void {
    if (++this.clicks >= 5) {
      this.reset();
      this.openPassLock();
    } else {
      this.setTimeout(() => this.reset(), 500);
    }
  }

  private reset(): void {
    this.clearTimeout();
    this.clicks = 0;
  }

  private clearTimeout(): void {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = null;
  }

  private setTimeout(f: Function, ms: number): void {
    this.clearTimeout();

    this.timeout = setTimeout(f, ms);
  }

  private async openPassLock(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: PassLockComponent,
      backdropDismiss: true,
      cssClass: 'pass-lock-modal modal-rounded'
    });

    modal.onDidDismiss().then((res: any) => {
      if (res.data && res.data.unlocked) {
        this.openAdminConfig();
      }
    });

    await modal.present();
  }

  private async openAdminConfig(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: AdminConfigComponent,
      backdropDismiss: false,
      cssClass: 'admin-config-modal modal-rounded'
    });

    await modal.present();
  }
}
