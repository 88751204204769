import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { Protagonist } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root'
})

export class ProtagonistService {
  constructor(private storage: StorageService) { }

  public getAll(): Promise<Protagonist[]> {
    return this.storage.getProtagonists();
  }

  public async getByID(id: number | string): Promise<Protagonist | undefined> {
    return (await this.getAll()).find((p: Protagonist) => p.id == id);
  }

  public async getBySlug(slug: string): Promise<Protagonist | undefined> {
    return (await this.getAll()).find((p: Protagonist) => p.slug == slug);
  }
}
