import { ILatLng, ILatLngBounds } from '@ionic-native/google-maps';
import { Route } from 'app/interfaces/api/types';

const EARTH_RADIUS = 6378137; // Earth’s mean radius in meter
export const HIDDEN_PLACE_THRESHOLD = 50; // Max. distance to trigger a hidden place notification
export const HIDDEN_PLACE_DEBOUNCE_RATE = 1000 * 60 * 5; // Minimum time to re-trigger an already entered hidden place notification

export function getCenter(coordinates: ILatLng[]): ILatLng | null {
  if (!coordinates.length) {
    return null;
  }
  let minLat: number;
  let maxLat: number;
  let minLng: number;
  let maxLng: number;
  for (const c of coordinates) {
    if (c !== null) {
      if (minLat === undefined || c.lat < minLat) {
        minLat = c.lat;
      }
      if (maxLat === undefined || c.lat > maxLat) {
        maxLat = c.lat;
      }
      if (minLng === undefined || c.lng < minLng) {
        minLng = c.lng;
      }
      if (maxLng === undefined || c.lng > maxLng) {
        maxLng = c.lng;
      }
    }
  }
  return {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2,
  };
}

export function getCenterOfBounds(bounds: ILatLngBounds): ILatLng {
  const width = bounds.northeast.lat - bounds.southwest.lat;
  const height = bounds.northeast.lng - bounds.southwest.lng;
  return {
    lat: bounds.southwest.lat + width / 2,
    lng: bounds.southwest.lng + height / 2,
  };
}

export function constrainPosition(
  position: ILatLng,
  visibleArea: ILatLngBounds,
  bounds: ILatLngBounds,
): ILatLng {
  const constrainedPosition = { ...position };
  const extendedBounds: ILatLngBounds = {
    northeast: {
      lat: bounds.northeast.lat + 0.05,
      lng: bounds.northeast.lng + 0.05,
    },
    southwest: {
      lat: bounds.southwest.lat - 0.05,
      lng: bounds.southwest.lng - 0.05,
    },
  };

  let northExceeded = false;
  let southExceeded = false;
  let eastExceeded = false;
  let westExceeded = false;

  if (visibleArea.northeast.lat > extendedBounds.northeast.lat) {
    northExceeded = true;
    constrainedPosition.lat =
      position.lat - (visibleArea.northeast.lat - extendedBounds.northeast.lat);
  }

  if (visibleArea.southwest.lat < extendedBounds.southwest.lat) {
    southExceeded = true;
    constrainedPosition.lat =
      position.lat - (visibleArea.southwest.lat - extendedBounds.southwest.lat);
  }

  if (visibleArea.northeast.lng > extendedBounds.northeast.lng) {
    eastExceeded = true;
    constrainedPosition.lng =
      position.lng - (visibleArea.northeast.lng - extendedBounds.northeast.lng);
  }

  if (visibleArea.southwest.lng < extendedBounds.southwest.lng) {
    westExceeded = true;
    constrainedPosition.lng =
      position.lng - (visibleArea.southwest.lng - extendedBounds.southwest.lng);
  }

  if (westExceeded && eastExceeded) {
    constrainedPosition.lng =
      bounds.northeast.lng + (bounds.southwest.lng - bounds.northeast.lng) / 2;
  }

  if (northExceeded && southExceeded) {
    constrainedPosition.lat =
      bounds.southwest.lat + (bounds.northeast.lat - bounds.southwest.lat) / 2;
  }

  return constrainedPosition;
}

export function getTrackCoordinates(route: Route = null): ILatLng[] {
  // Check for tracks
  if (route && route.gpx && route.gpx.trkseg && route.gpx.trkseg.length) {
    return route.gpx.trkseg[0].points.map(point => ({
      lat: point.lat,
      lng: point.lon,
    }));
  }

  // Check for waypoints
  if (route && route.gpx && Array.isArray(route.gpx)) {
    return route.gpx.map((point) => ({
      lat: point.lat,
      lng: point.lon
    }));
  }

  return [];
}

function rad(x) {
  return x * Math.PI / 180;
};

function deg(x) {
  return x * 180 / Math.PI;
}

export function getDistance(p1: ILatLng, p2: ILatLng) {
  const deltaLat = rad(p2.lat - p1.lat);
  const deltaLng = rad(p2.lng - p1.lng);
  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
    Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return EARTH_RADIUS * c;
};

export function getAngleFromPointToPoint(p1: ILatLng, p2: ILatLng) {
  const deltaLng = p2.lng - p1.lng;
  const y = Math.sin(deltaLng) * Math.cos(p2.lat);
  const x = Math.cos(p1.lat) * Math.sin(p2.lat) - Math.sin(p1.lat) * Math.cos(p2.lat) * Math.cos(deltaLng);
  let angle = Math.atan2(y, x);
  angle = deg(angle);
  angle = (angle + 360) % 360;
  return angle;
};
