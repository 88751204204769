import { GoogleMap, ILatLng, Marker } from '@ionic-native/google-maps';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { StorageService } from 'app/services';

export default class UserLocator {
  private storageService: StorageService;
  private marker: Marker;
  private opacity = 0;
  private pulsateVelocity = 0.05;
  private interval: any;
  private running = false;
  private position: ILatLng = { lat: 51.5137, lng: 7.470967 };

  constructor(private map: GoogleMap) {
    this.storageService = AppInjector.prepare().get(StorageService);
  }

  setPosition(position: ILatLng) {
    if (!this.running) {
      this.start();
    }
    this.position = position;

    if (this.marker) {
      this.marker.setVisible(true);
      this.marker.setPosition(this.position);
    }
  }

  async init() {
    this.marker = await this.map.addMarker({
      position: this.position,
      icon: {
        url: await this.storageService.loadAsset('/assets/img/map/icon-circle-user-location.png'),
        size: { width: 20, height: 20 },
        anchor: [10, 10],
      },
      visible: false,
    });
  }

  start() {
    if (!this.marker) {
      return;
    }
    this.running = true;
    this.marker.setVisible(true);
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.marker) {
        if (this.opacity >= 0.75) {
          this.opacity = 0.75;
          this.pulsateVelocity *= -1;
        } else if (this.opacity <= 0.25) {
          this.opacity = 0.25;
          this.pulsateVelocity *= -1;
        }
        this.opacity += this.pulsateVelocity;
        this.marker.setOpacity(this.opacity);
      }
    }, 60);
  }

  stop() {
    this.running = false;
    clearInterval(this.interval);
    this.opacity = 0;
    if (this.marker) {
      this.marker.setOpacity(this.opacity);
    }
  }

  setVisible(visible: boolean) {
    if (this.marker) {
      this.marker.setVisible(visible);
      this.marker.setOpacity(this.opacity);
    }
  }
}
