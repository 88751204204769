import { Router } from '@angular/router';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { Listener } from './listener';

import * as jQuery from 'jquery';
import { SettingsService } from 'app/services';

export class NavLinkListener implements Listener {
  private $: JQueryStatic = jQuery;

  private router: Router;
  private settingsService: SettingsService;

  constructor() {
    this.router = AppInjector.prepare().get(Router);
    this.settingsService = AppInjector.prepare().get(SettingsService);
  }

  public start(): void {
    this.$(document.body).on('click', '[data-href]', (e) => this.onDataHrefClick(e));
    this.$(document.body).on('click', '[href]', (e) => this.onHrefClick(e));
  }

  private onDataHrefClick(e: any): void {
    const href: string = this.$(e.currentTarget).data('href');

    this.router.navigateByUrl(href);
  }

  private onHrefClick(e: any): void {
    if (this.settingsService.getKioskModeActive()) {
      e.preventDefault();
    }
  }
}
