import * as jQuery from 'jquery';


export class AudioHelper {
  private static $: JQueryStatic = jQuery;

  public static stopAllAudios() {
    this.$('audio').trigger('pause');
    this.$('div.waveform')
      .find('button > .audio-control')
      .removeClass('pause')
      .addClass('play');
  }
}