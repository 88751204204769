import { ElementRef } from '@angular/core';
import { Location, Protagonist, Route, Space, Story } from 'app/interfaces/api/types';
import { ProtagonistService, RouteService } from 'app/services';

import { AppInjector } from 'app/classes/app-injector/app-injector';

import * as jQuery from 'jquery';

export class StoryChooser {
  private element: ElementRef;
  private $: JQueryStatic = jQuery;
  private location: Location;
  private stories: Story[] = [];
  private space: Space;
  private items: { story: Story; protagonist: Protagonist }[] = [];
  private protagonistService: ProtagonistService;
  private routeService: RouteService;

  public routes: Route[] = [];

  constructor() {
    this.protagonistService = AppInjector.prepare().get(ProtagonistService);
    this.routeService = AppInjector.prepare().get(RouteService);
  }

  public setElement(element: ElementRef) {
    this.element = element;

    this.updateSlider();
  }

  public async setLocation(location: Location): Promise<void> {
    this.location = location;
    this.routes = await this.routeService.getByIDs(this.location.routes);
  }

  setSpace(space: Space) {
    this.space = space;
  }

  getSpace(): Space {
    return this.space;
  }

  async setStories(stories: Story[]): Promise<void> {
    this.stories = stories;
    this.items = [];

    for (const s of this.stories) {
      if (s.protagonist) {
        this.items.push({
          story: s,
          protagonist: await this.protagonistService.getByID(s.protagonist)
        });
      }
    }

    this.updateSlider();
  }

  private updateSlider(): void {
    if (this.element) {
      const slider: HTMLDivElement|null = this.element.nativeElement.querySelector('div.story-list-slider');

      if (slider) {
        /** @see src/assets/css/_story-chooser.scss:62 */
        slider.setAttribute('data-total-protagonists', String(this.items.length));
      }
    }
  }

  public getItems() {
    return this.items;
  }

  public show() {
    this.$(this.element.nativeElement)
      .stop()
      .clearQueue()
      .fadeIn();
  }

  public hide() {
    this.$(this.element.nativeElement)
      .stop()
      .clearQueue()
      .fadeOut();
  }
}
