import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController, MenuController, Platform, ModalController, AlertController, IonicModule } from '@ionic/angular';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Router } from '@angular/router';
import { Menu, MenuItem, Page } from 'app/interfaces/api/types';

import { PassLockComponent } from 'app/components/pass-lock/pass-lock.component';
import { AdminConfigComponent } from 'app/components/admin-config/admin-config.component';
import { AppBannerComponent } from 'app/components/app-banner/app-banner.component';

import { Progress } from 'app/utils';

import {
  MenuService,
  SettingsService,
  StorageService,
  ActivityService,
  TranslatorService,
  HistoryService
} from 'app/services';

import {
  GlossaryLinkListener,
  AccordionListener,
  NavLinkListener,
  ImageViewerListener,
  FirebaseAnalyticsListener,
  ImageComparisonSliderListener,
  WavesurferListener,
  SvgExtractorListener,
  ImageSlidesListener,
  InterfererListener,
  VideoListener,
  DocumentStyleListener
} from 'app/utils/listeners';

import { Environment } from 'environments/environment'

import * as jQuery from 'jquery';
import { RdiWindow } from './interfaces/rdi';

type AppPage = {
  title: string;
  url: string;
  class: string;
};

declare var window: RdiWindow;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  public pages: Array<Page> = [];
  public appPages: Array<AppPage> = [];
  public appVersion: string = Environment.getAppVersion();
  public showSplash: boolean = true;
  public loadingProgress = 0.0;
  public hideSettings: boolean;
  public stelenActive: boolean = false;

  private time: number;

  constructor(
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly menuController: MenuController,
    private readonly menuService: MenuService,
    private readonly modalCtrl: ModalController,
    private readonly alertCtrl: AlertController,
    private readonly navController: NavController,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly activityService: ActivityService,
    private readonly settings: SettingsService,
    private readonly translator: TranslatorService,
    private readonly historyService: HistoryService,
    private readonly facebook: Facebook,
  ) {
    this.initializeApp();
  }

  async initializeApp(): Promise<void> {
    this.initRedirect();
    this.initLanguage();

    await this.platform.ready();
    await this.storageService.ready();
    await this.initFacebook();
    await this.prepareKioskMode();
    await this.prepareStelenFeature();

    this.historyService.init();
    this.splashScreen.hide();
    this.statusBar.styleDefault();
    this.statusBar.backgroundColorByHexString('#002c42');
    this.statusBar.overlaysWebView(false);
    this.translator.languageChange.subscribe(() => window.location.reload());

    if (this.settings.getOfflineContentActive()) {
      this.loadingProgress = 100;
    } else {
      await this.storageService.withoutCache(async (): Promise<void> => {
        const language: string = this.translator.getLanguage();
        const onProgress: Function = (p: Progress) => this.loadingProgress = p.progress / p.total * 100;

        await this.storageService.downloadPosts(language, onProgress);
      });
    }

    await this.prepareMenu();
    await this.prepareListeners();

    await this.hideCustomSplashScreen();

    if (this.settings.getShowOfflineUsage()) {
      await this.showOfflineUsage();
    }

    if (this.shouldShowAppBanner()) {
      await this.showAppBanner();
    }

    window.rdi.appReady = true;
  }

  private async initFacebook(): Promise<void> {
    await this.facebook.setAdvertiserTrackingEnabled(true).catch(() => { });
    await this.facebook.setAdvertiserIDCollectionEnabled(true).catch(() => { });
    await this.facebook.setAutoLogAppEventsEnabled(true).catch(() => { });
  }

  public toggleMenu(): void {
    this.menuController.toggle();
  }

  public hideCustomSplashScreen(): Promise<void> {
    return new Promise((resolve) => {
      jQuery('.splash').fadeOut(600, 'swing', () => {
        this.showSplash = false;
        resolve();
      });
    });
  }

  private async prepareMenu(): Promise<void> {
    const menu: Menu = await this.menuService.getAppMenu();
    const routePrefixMap: object = {
      custom: '/',
      page: '/p/',
      space: '/space/',
    };

    menu.forEach((item: MenuItem) => {
      const item_class: string = item.classes.length ? item.classes.join(' ') : '';
      const prefix: string = routePrefixMap[item.type];
      const url: string = `${prefix}${item.slug}`;

      this.appPages.push({
        title: item.name,
        url: url,
        class: item_class,
      });
    });
  }

  private prepareKioskMode(): void {
    this.settings.setKioskModeActive(this.settings.getKioskModeActive());

    this.hideSettings = this.settings.getHideSettings();

    this.settings.hideSettingsChange.subscribe((hide: boolean) => this.hideSettings = hide);
  }

  private async prepareStelenFeature(): Promise<void> {
    this.stelenActive = this.settings.getStelenActive();

    this.activityService.onInactive.subscribe(() => {
      const shouldRedirect: boolean = (
        this.stelenActive &&
        this.router.url != '/home' &&
        this.settings.getStelenInactivityRedirect()
      );

      if (shouldRedirect) {
        this.router.navigateByUrl('/home');
      }
    });

    if (this.settings.getStelenActive()) {
      await this.navController.navigateRoot('/home');
    }
  }

  public touchStart(): void {
    this.time = Date.now();
  }

  public touchEnd(): void {
    const seconds: number = (new Date(Date.now() - this.time)).getUTCSeconds();

    if (seconds >= 5) {
      this.openPassLock();
    }
  }

  public changeLanguage(language: string): void {
    this.translator.setLanguage(language);
  }

  public async openAdminConfig(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: AdminConfigComponent,
      backdropDismiss: false,
      cssClass: 'admin-config-modal modal-rounded'
    });

    await modal.present();
  }

  public async openPassLock(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: PassLockComponent,
      backdropDismiss: true,
      cssClass: 'pass-lock-modal modal-rounded'
    });

    modal.onDidDismiss().then((res: any) => {
      if (res.data && res.data.unlocked) {
        this.openAdminConfig();
      }
    });

    await modal.present();
  }

  private async showOfflineUsage(): Promise<void> {
    const alert: any = await this.alertCtrl.create({
      header: this.translator.translate('offline_usage'),
      message: this.translator.translate('offline_usage_text'),
      cssClass: 'offline-usage-alert',
      backdropDismiss: false,
      buttons: [
        {
          text: this.translator.translate('no_thanks')
        },
        {
          text: this.translator.translate('settings'),
          handler: () => this.router.navigateByUrl('/settings')
        }
      ]
    });

    alert.onDidDismiss().then(() => this.settings.setShowOfflineUsage(false));

    await alert.present();
  }

  public async showAppBanner(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: AppBannerComponent,
      backdropDismiss: false,
      cssClass: 'app-banner-modal'
    });

    modal.onDidDismiss().then(() => this.settings.setAppBannerLastShown(Date.now()));

    await modal.present();
  }

  private async prepareListeners(): Promise<void> {
    await Promise.all([
      new GlossaryLinkListener().start(),
      new AccordionListener().start(),
      new NavLinkListener().start(),
      new ImageViewerListener().start(),
      new FirebaseAnalyticsListener().start(),
      new ImageComparisonSliderListener().start(),
      new WavesurferListener().start(),
      /**
       * Disabling SvgExtractor. Also partly disabled by previous changes in the following files:
       * @see svg-extractor.listener.ts:92-93
       * @see single-protagonist-page.scss:9-13
       */
      // new SvgExtractorListener().start(),
      new ImageSlidesListener().start(),
      new InterfererListener().start(),
      new VideoListener().start(),
      new DocumentStyleListener().start(),
    ]);
  }

  /**
   * Check if language must be changed.
   * App equivalent is found in "index.html" (handleOpenURL).
   */
  private initLanguage(): void {
    const url: URL = new URL(window.location.href);

    if (url.searchParams.has('lang')) {
      const language: string = url.searchParams.get('lang');
      const valid: boolean = this.translator.isValidLanguage(language);

      if (valid && language != this.translator.getLanguage()) {
        this.translator.setLanguage(language);
      }

      window.location.href = window.location.pathname;
    }
  }

  private initRedirect(): void {
    /* See index.html -> handleOpenURL */
    window.addEventListener('rdi::urlRedirect', (e: any) => {
      localStorage.removeItem('rdi::urlRedirect');

      window.location = e.detail;
    });

    const redirect: string = localStorage.getItem('rdi::urlRedirect');

    if (redirect) {
      window.dispatchEvent(new CustomEvent('rdi::urlRedirect', { detail: redirect }));
    }
  }

  private shouldShowAppBanner(): boolean {
    const supportedPlatforms: any = ['android', 'iphone', 'ipad']
    const supported: boolean = supportedPlatforms.some((p: any) => this.platform.is(p));

    if (window.rdi.browserBuild && supported) {
      const lastShown: number = this.settings.getAppBannerLastShown() || 0;
      const maxHours: number = 1000 * 60 * 60 * 24; // 24 hours

      return (Date.now() - lastShown) >= maxHours;
    } else {
      return false;
    }
  }
}
