import { Component, OnInit } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent implements OnInit {
  constructor(
    private navController: NavController,
    private menuController: MenuController
  ) { }

  ngOnInit(): void { }

  public goToHome(): void {
    this.menuController.close();
    this.navController.navigateRoot('/');
  }
}
