import { Injectable } from '@angular/core';

import { StorageService } from 'app/services/storage/storage.service';

import { Route } from 'app/interfaces/api/types';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  constructor(private storage: StorageService) {}

  public getAll(): Promise<Route[]> {
    return this.storage.getRoutes();
  }

  public async getByID(id: number | string): Promise<Route | undefined> {
    return (await this.getAll()).find((r: Route) => r.id == id);
  }

  public async getByIDs(ids: number[]): Promise<Route[]> {
    return await Promise.all(ids.map((id: number) => this.getByID(id)));
  }

  public async getBySlug(slug: string): Promise<Route | undefined> {
    return (await this.getAll()).find((r: Route) => r.slug == slug);
  }
}
