import { Injectable } from '@angular/core';

import { Menu, MenuItem } from 'app/interfaces/api/types';

import { StorageService } from 'app/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class MenuService {
  constructor(private storage: StorageService) { }

  public getAppMenu(): Promise<Menu> {
    return this.storage.getAppMenu();
  }

  public getStelenMenu(): Promise<Menu> {
    return this.storage.getStelenMenu();
  }
}
