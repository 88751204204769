import { Injectable } from '@angular/core';
import { RdiWindow } from 'app/interfaces/rdi';

import { KioskModeMock } from 'app/utils/kiosk';

import { BehaviorSubject } from 'rxjs';

declare var window: RdiWindow;

@Injectable({
  providedIn: 'root'
})

export class SettingsService {
  public readonly offlineContentChange: BehaviorSubject<boolean>;
  public readonly offlineMapChange: BehaviorSubject<boolean>;
  public readonly kioskModeChange: BehaviorSubject<boolean>;
  public readonly hideSettingsChange: BehaviorSubject<boolean>;
  public readonly stelenChange: BehaviorSubject<boolean>;
  public readonly stelenInactivityRedirect: BehaviorSubject<boolean>;

  private tooltipShown: boolean = false;

  constructor() {
    this.offlineContentChange = new BehaviorSubject(this.getOfflineContentActive());
    this.offlineMapChange = new BehaviorSubject(this.getOfflineMapActive());
    this.kioskModeChange = new BehaviorSubject(this.getKioskModeActive());
    this.hideSettingsChange = new BehaviorSubject(this.getHideSettings());
    this.stelenChange = new BehaviorSubject(this.getStelenActive());
    this.stelenInactivityRedirect = new BehaviorSubject(this.getStelenInactivityRedirect());
  }

  private get kioskMode(): any {
    return window.KioskMode || KioskModeMock;
  }

  public setOfflineContentActive(active: boolean): void {
    localStorage.setItem('settings::offlineContent', String(active));

    this.offlineContentChange.next(active);
  }

  public getOfflineContentActive(): boolean {
    return (localStorage.getItem('settings::offlineContent') == 'true');
  }

  public setOfflineMapActive(active: boolean): void {
    localStorage.setItem('settings::offlineMap', String(active));

    this.offlineMapChange.next(active);
  }

  public getOfflineMapActive(): boolean {
    return (localStorage.getItem('settings::offlineMap') == 'true');
  }

  public setKioskModeActive(active: boolean): void {
    localStorage.setItem('settings::kioskMode', String(active));

    if (active) {
      this.kioskMode.enable().catch(console.warn);
    } else {
      this.kioskMode.disable().catch(console.warn);
    }

    this.kioskModeChange.next(active);
  }

  public getKioskModeActive(): boolean {
    return (localStorage.getItem('settings::kioskMode') == 'true');
  }

  public setHideSettings(hide: boolean): void {
    localStorage.setItem('settings::hideSettings', String(hide));

    this.hideSettingsChange.next(hide);
  }

  public getHideSettings(): boolean {
    return (localStorage.getItem('settings::hideSettings') == 'true') || window.rdi.browserBuild;
  }

  public setStelenActive(active: boolean): void {
    localStorage.setItem('settings::stelenActive', String(active));

    this.stelenChange.next(active);
  }

  public getStelenActive(): boolean {
    return (localStorage.getItem('settings::stelenActive') == 'true');
  }

  public setStelenInactivityRedirect(active: boolean): void {
    localStorage.setItem('settings::stelenInactivityRedirect', String(active));

    this.stelenInactivityRedirect.next(active);
  }

  public getStelenInactivityRedirect(): boolean {
    return (localStorage.getItem('settings::stelenInactivityRedirect') == 'true');
  }

  public setShowOfflineUsage(show: boolean): void {
    localStorage.setItem('settings::showOfflineUsage', String(show));
  }

  public getShowOfflineUsage(): boolean {
    return !window.rdi.browserBuild && localStorage.getItem('settings::showOfflineUsage') != 'false';
  }

  public setAppBannerLastShown(timestamp: number): void {
    localStorage.setItem('settings::appBannerLastShown', String(timestamp));
  }

  public getAppBannerLastShown(): number | undefined {
    const timestamp: number = parseInt(localStorage.getItem('settings::appBannerLastShown'));

    return (isNaN(timestamp)) ? undefined : timestamp;
  }

  public setTooltipShown(): void {
    this.tooltipShown = true;
  }

  public getTooltipShown(): boolean {
    return this.tooltipShown;
  }

  public setLastUpdatedAt(timestamp: number): void {
    localStorage.setItem('settings::lastUpdatedAt', String(timestamp));
  }

  public getLastUpdatedAt(): number | undefined {
    const timestamp: number = parseInt(localStorage.getItem('settings::lastUpdatedAt'));

    return (isNaN(timestamp)) ? undefined : timestamp;
  }
}
