import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Space } from 'app/interfaces/api/types';

@Component({
  selector: 'space-list',
  templateUrl: './space-list.component.html',
  styleUrls: ['./space-list.component.scss'],
})

export class SpaceListComponent implements OnInit {
  @Input() spaces: Space[] = [];
  @Output() spaceClick: EventEmitter<Space> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
