import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private readonly MAX_HISTORY_LENGTH: number = 50;
  private readonly history: string[] = [];

  private index: number = -1;

  private didInit: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly navController: NavController
  ) { }

  public init(): void {
    if (!this.didInit) {
      this.router.events
        .pipe(filter((e: any) => e instanceof NavigationEnd))
        .subscribe((e: any) => this.addToHistory(e.urlAfterRedirects));

      this.didInit = true;
    }
  }

  public canGoForward(): boolean {
    return this.index < this.history.length - 1;
  }

  public async goForward(): Promise<boolean | void> {
    if (this.canGoForward()) {
      return await this.navController.navigateRoot(this.getNextUrl());
    }
  }

  public canGoBack(): boolean {
    return this.index > 0 && this.history.length > 1;
  }

  public goBack(): void {
    if (this.canGoBack()) {
      this.navController.navigateRoot(this.getPreviousUrl());
    }
  }

  private addToHistory(url: string): void {
    if (this.getPreviousUrl() === url) {
      this.index--;
    } else if (this.getNextUrl() === url) {
      this.index++;
    } else {
      this.history.push(url);

      this.sanitizeHistory();

      this.index = this.history.length - 1;
    }
  }

  private sanitizeHistory(): void {
    while (this.history.length > this.MAX_HISTORY_LENGTH) {
      this.history.shift();
    }
  }

  public getCurrentUrl(): string {
    return this.getByIndex(this.index);
  }

  public getPreviousUrl(): string {
    return this.getByIndex(this.index - 1);
  }

  public getNextUrl(): string {
    return this.getByIndex(this.index + 1);
  }

  private getByIndex(index: number): string {
    return (this.history.length > index) ? this.history[index] : null;
  }
}
