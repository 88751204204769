import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Environment } from 'environments/environment';

@Component({
  selector: 'pass-lock',
  templateUrl: './pass-lock.component.html',
  styleUrls: ['./pass-lock.component.scss'],
})

export class PassLockComponent implements OnInit {
  @ViewChild('passInput', { read: ElementRef, static: false }) passInput: ElementRef;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void { }

  public dismiss(password?: string): void {
    this.modalCtrl.dismiss({
      unlocked: (password === Environment.getAppPassword())
    });
  }
}
