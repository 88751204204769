export default {
  754: { x: 61, y: 65 }, // Muttental
  1125: { x: 53, y: 55 }, // Erzbahntrasse
  859: { x: 45, y: 54 }, // Zollverein
  1772: { x: 61, y: 65 }, // Muttental
  2202: { x: 53, y: 55 }, // Erzbahntrasse
  1508: { x: 45, y: 54 }, // Zollverein,
  4872: { x: 30, y: 57 }, // Ruhrort DE
  4968: { x: 30, y: 57 }, // Ruhrort EN
};
