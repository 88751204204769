import { ILatLng } from '@ionic-native/google-maps';
import { Location, Space } from './api/types';

export enum ElementType {
  SPACE = 'space',
  LOCATION = 'location',
  STORY = 'story',
  GROUND_OVERLAY = 'ground_overlay',
  ROUTE_PATH = 'route_path',
  HIGHLIGHT_ROUTE_SEGMENT = 'highlight_route_segment',
  ROUTE_ARROW = 'route_arrow',
  NAVIGATION = 'navigation', // User locator etc.
}
export type SpaceWithCenter = [Space, ILatLng];

export interface MapInteractors {
  rdiOverlay: any;
  markers: {}[];
}

export interface GPXDocument {
  gpx: {
    trk: {
      trkseg: {
        trkpt: { _lat: number; _lon: number }[];
      };
    };
  };
}

export interface HiddenPlace {
  location: Location;
  lastVisited: Date | null;
}
