import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@ionic-native/file/ngx';
import { Environment, GoogleMap, GoogleMapOptions, GoogleMaps, GoogleMapsEvent, GoogleMapsMapTypeId, ILatLng, LatLng, LatLngBounds } from '@ionic-native/google-maps';
import { NavController, Platform, ModalController } from '@ionic/angular';
import { LocationType, rdiBounds } from 'app/constants';
import { Location, Route, Story, Space} from 'app/interfaces/api/types';
import { HiddenPlace, SpaceWithCenter } from 'app/interfaces/map';
import { getCenter, getCenterOfBounds, getDistance, HIDDEN_PLACE_DEBOUNCE_RATE, HIDDEN_PLACE_THRESHOLD } from 'app/utils/map';
import { Environment as RdiEnvironment } from 'environments/environment';
import { browserStyles, mobileStyles } from './map-style';
import { addRouteArrows, hideAllInfoWindows, initialMapZoom, initUserLocator, initZoomElements, maxZoom, minZoom, registerLocationMarker, registerRoutePath, registerSpaceMarker, showElementsBasedOnZoom, stopUserLocator, updateRoutes, updateUserLocator, zoomAndTargetLocation, clearMapSubscriptions, initMap } from './map.drawhelper';
import { StoryChooser } from './storychooser';
import { LocationNotificationComponent } from 'app/components/location-notification/location-notification.component';
import { Subscription } from 'rxjs';

import * as jQuery from 'jquery';

import {
  ContextService,
  LocationService,
  ProtagonistService,
  RouteService,
  SettingsService,
  SpaceService,
  StoryService,
  StorageService
} from 'app/services';
import { MapTilehelper } from './map.tilehelper';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('storyChooserElement', { read: ElementRef, static: false })
  private storyChooserElement: ElementRef;

  @ViewChild('mapElement', { read: ElementRef, static: false })
  private mapElement: ElementRef;

  @Input() currentLocation: Location;
  @Input() currentStory: Story;
  @Input() onShowMore: Function;
  @Input() onShouldDismiss: Function;

  public contextRoute: Route;
  public storyChooser: StoryChooser;
  public currentUserLocation: ILatLng;
  public mapViewportIsOutsideConstraints: boolean;
  public defaultProtagonistAvatar: string = RdiEnvironment.getDefaultProtagonistAvatar();

  private map: GoogleMap;
  private geolocationWatchId: number;
  private spacesWithCenters: SpaceWithCenter[];

  private rdiBounds: LatLngBounds;
  private hiddenPlaces: HiddenPlace[] = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private platform: Platform,
    private storageService: StorageService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private spaceService: SpaceService,
    private routeService: RouteService,
    private locationService: LocationService,
    private storyService: StoryService,
    private contextService: ContextService,
    private settingsService: SettingsService,
  ) {
    this.storyChooser = new StoryChooser();
  }

  async ngOnInit(): Promise<void> {
    await this.platform.ready();

    initMap();
    initZoomElements();

    this.storyChooser = new StoryChooser();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.platform.ready();

    this.rdiBounds = new LatLngBounds([
      rdiBounds.southwest,
      rdiBounds.northeast,
    ]);

    initMap();
    initZoomElements();

    this.subscription.add(
      this.contextService.routeContextChange
        .subscribe((r: Route) => this.contextRoute = r)
    );

    // wait until all native google maps functions are available
    await this.initMap();

    this.storyChooser.setElement(this.storyChooserElement);
  }

  async ngOnDestroy(): Promise<void> {
    navigator.geolocation.clearWatch(this.geolocationWatchId);
    stopUserLocator();
    this.subscription.unsubscribe();

    clearMapSubscriptions();

    this.map && await this.map.clear();
    this.map && await this.map.destroy();
  }

  private async initMap(): Promise<void> {
    Environment.setEnv({
      API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyAwjBxHCGhtodr-RjOPYbsVuVCGyq_G6D0',
      API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyAwjBxHCGhtodr-RjOPYbsVuVCGyq_G6D0', // empty to run in development mode
    });

    await this.loadMapResources();

    const mapOptions: GoogleMapOptions = {
      styles: this.settingsService.getOfflineMapActive()
        ? mobileStyles
        : browserStyles,
      mapType: this.settingsService.getOfflineMapActive()
        ? GoogleMapsMapTypeId.NONE
        : GoogleMapsMapTypeId.NORMAL,
      controls: {
        zoom: false,
        myLocation: true,
        myLocationButton: false,
      },
      gestures: {
        scroll: true,
        tilt: false,
        rotate: false,
        zoom: true,
      },
      camera: {
        target: getCenter(
          this.spacesWithCenters.filter(c => c[1]).map(c => c[1]),
        ),
        zoom: initialMapZoom,
      },

      preferences: {
        disableDefaultUi: true,

        zoom: {
          minZoom: minZoom,
          maxZoom: maxZoom,
        },
      },
    };

    this.map = GoogleMaps.create(this.mapElement.nativeElement, mapOptions);

    if (this.settingsService.getOfflineMapActive()) {
      const tilehelper: MapTilehelper = new MapTilehelper(this.map);
      const prefix: string = this.storageService.tilesLocation;

      const getTile = (x, y, z) => tilehelper.getTile(x, y, z, prefix);

      /** @debug */
      // const getTile = (x, y, z) => tilehelper.collectTile(x, y, z, prefix);
      // (window as any).tilehelper = tilehelper;

      this.map.addTileOverlay({ getTile });
    }

    const mapSubscription = this.map.on(GoogleMapsEvent.MAP_READY)
      .subscribe(async () => {
        try {
          this.geolocationWatchId = navigator.geolocation.watchPosition(
            pos => {
              this.currentUserLocation = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
              };
              updateUserLocator(this.currentUserLocation);
              this.checkForHiddenPlace();
            },
            error => {
              if (
                error.code === error.PERMISSION_DENIED ||
                error.code === error.POSITION_UNAVAILABLE
              ) {
                stopUserLocator();
              }
              //console.log(error);
            },
            {
              timeout: 5000,
              enableHighAccuracy: true,
            },
          );

          await this.addMapInteractors();

          this.bindMapSubscribers();
          showElementsBasedOnZoom(this.map.getCameraZoom());

          const route = await this.contextService.getActiveRoute();

          if (route) {
            updateRoutes(route, route.locations[0], this.map);
          }
        } catch (e) {
          console.log(e);
        }

        this.contextRoute = await this.contextService.getActiveRoute();

        if (!this.contextRoute) {
          const contextRouteId: number = this.spacesWithCenters[0][0].routes[0];
          const contextRoute: Route = await this.routeService.getByID(contextRouteId);

          this.contextService.setActiveRoute(contextRoute);
        }
      });

    const mapClickSubscription = this.map.on(GoogleMapsEvent.MAP_CLICK)
      .subscribe(() => {
        this.storyChooser.hide();
        hideAllInfoWindows();
      });

    this.subscription.add(mapSubscription);
    this.subscription.add(mapClickSubscription);
  }

  private async loadMapResources(): Promise<void> {
    const spaces: Space[] = await this.spaceService.getAll();
    const locations: Location[] = await this.locationService.getAll();

    this.spacesWithCenters = spaces.map<SpaceWithCenter>((s) => [s, s.geolocations[0]]);

    this.hiddenPlaces = locations
      .filter(l => l.type_slug === LocationType.HIDDEN_PLACE && l.geolocations.length > 0)
      .map(l => ({ location: l, lastVisited: null }));
  }

  private async onShowMoreClicked(location: Location): Promise<void> {
    if (this.onShowMore) {
      this.onShowMore(this.currentLocation, location);
    } else {
      if (location.stories) {
        const story = await this.storyService.getByID(location.stories[0]);

        this.goToStory(story, true);
      } else {
        console.warn(`No story found for location ${location.id}`);
      }
    }
  }

  private async addMapInteractors(): Promise<void> {
    const routes: Route[] = await this.routeService.getAll();

    // iterate over spaces
    for (const spaceWithCenter of this.spacesWithCenters) {
      if (spaceWithCenter[1]) {
        if (spaceWithCenter[0].routes.length) {
          // register space marker
          await registerSpaceMarker(
            spaceWithCenter,
            this.map,
            this.storyChooser,
            () => this.goToSpace(spaceWithCenter[0].slug)
          );

          // iterate over routes
          for (const routeId of spaceWithCenter[0].routes) {
            const r = await this.routeService.getByID(routeId);
            // register route line
            const routeSegments = await registerRoutePath(r, this.map);

            await Promise.all(
              r.locations.map(async locationId => {
                const location = await this.locationService.getByID(locationId);

                /**
                 * @description
                 * Find the correct route to draw.
                 * Problem: A location can have multiple routes and thus multiple indices.
                 * Solution: We either look for the context Route or the route with the maximum amount of locations.
                 */
                let maxLocations: number = -1;

                location.primaryRouteIndex = undefined;

                Object.entries(location.indexByRoute || {}).forEach(([routeId, index]) => {
                  const route: Route = routes.find(({ id }) => id === Number(routeId));

                  if (this.contextRoute && this.contextRoute.id === Number(routeId)) {
                    location.primaryRouteIndex = index;
                  } else if (route && route.locations && route.locations.length > maxLocations) {
                    maxLocations = route.locations.length;
                    location.primaryRouteIndex = index;
                  }
                });

                return registerLocationMarker(
                  location,
                  this.map,
                  this.storyChooser,
                  () => this.onShowMoreClicked(location)
                );
              }),
            );

            if (!this.platform.is('desktop')) {
              addRouteArrows(r, this.map, routeSegments);
            }
          }
        }
      }
    }

    this.onAfterAddMapInteractors();
  }

  private async onAfterAddMapInteractors(): Promise<void> {
    await initUserLocator(this.map);

    if (this.currentLocation) {
      zoomAndTargetLocation(
        this.currentLocation,
        this.map,
        this.storyChooser,
        () => this.onShowMoreClicked(this.currentLocation)
      );
    } else {
      showElementsBasedOnZoom(this.map.getCameraZoom());

      if (this.contextRoute) {
        updateRoutes(this.contextRoute, this.contextRoute.locations[0], this.map);
      }
    }
  }

  private bindMapSubscribers(): void {
    this.map.on(GoogleMapsEvent.CAMERA_MOVE_END)
      .subscribe(() => showElementsBasedOnZoom(this.map.getCameraZoom()));

    this.map.on(GoogleMapsEvent.MAP_DRAG_END).subscribe(() => {
      const cameraTarget: ILatLng = this.map.getCameraTarget();

      this.mapViewportIsOutsideConstraints = !this.rdiBounds.contains(cameraTarget);
    });
  }

  public goToSpace(slug: number | string): void {
    this.navCtrl.navigateRoot(`/space/${slug}`);
  }

  public setRoute(route: Route): void {
    this.contextService.setActiveRoute(route);

    updateRoutes(route, this.currentLocation ? this.currentLocation.id : undefined, this.map);
  }

  public goToStory(story: Story, showStoryteller: boolean = false): void {
    this.storyChooser.hide();

    const postfix: string = showStoryteller ? '/storyteller' : '';

    if (this.currentStory && this.currentStory.id === story.id) {
      this.onShouldDismiss();
    } else {
      this.navCtrl.navigateRoot(`/story/${story.slug}${postfix}`);
    }
  }

  public centerMapToUserLocation(): void {
    if (this.currentUserLocation) {
      this.map.animateCamera({
        target: this.currentUserLocation,
        duration: 500,
      });
      this.mapViewportIsOutsideConstraints = !this.rdiBounds.contains(
        this.currentUserLocation,
      );
    }
  }

  public centerMapToCenterOfContraints(): void {
    this.map.animateCamera({
      target: getCenterOfBounds(this.rdiBounds),
      duration: 500,
      zoom: initialMapZoom,
    });

    this.mapViewportIsOutsideConstraints = false;
  }

  public scrollStoryList(operator: string): void {
    const width: number = jQuery('.story-list-item').width() + 20;

    jQuery('.story-list-slider').animate({
      scrollLeft: `${operator}=${width}px`,
    });
  }

  private async enterHiddenPlace(place: HiddenPlace): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: LocationNotificationComponent,
      backdropDismiss: false,
      cssClass: 'location-notification-modal',
      componentProps: {
        location: place.location
      }
    });

    await modal.present();
  }

  private checkForHiddenPlace(): void {
    const now = new Date();

    for (let i = 0; i < this.hiddenPlaces.length; i++) {
      const place = this.hiddenPlaces[i];
      const d = getDistance(place.location.geolocations[0], this.currentUserLocation);
      if (
        d <= HIDDEN_PLACE_THRESHOLD
        && (
          place.lastVisited === null
          || place.lastVisited.getTime() < (now.getTime() - HIDDEN_PLACE_DEBOUNCE_RATE)
        )
      ) {
        this.enterHiddenPlace(place);
        place.lastVisited = now;
      }
    }
  }
}
