import { Listener } from './listener';
import { Attachment } from 'app/interfaces/api/types';
import { Platform, ModalController } from '@ionic/angular';
import { AttachmentService } from 'app/services';
import { AppInjector } from 'app/classes/app-injector/app-injector';
import { SingleVideoComponent } from 'app/components/modals/single-video/single-video.component';

import * as jQuery from 'jquery';
import { ModalHelper } from 'app/classes/modal-helper/modal-helper';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;

export class InterfererListener implements Listener {
  private $: JQueryStatic = jQuery;

  private modalController: ModalController;
  private attachmentService: AttachmentService;
  private platform: Platform;

  constructor() {
    this.modalController = AppInjector.prepare().get(ModalController);
    this.attachmentService = AppInjector.prepare().get(AttachmentService);
    this.platform = AppInjector.prepare().get(Platform);
  }

  public start(): void {
    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        await this.handleInterferers(element);
        await this.handleInterferers(document.body);
      } catch(err) {
        console.log(err);
      }
    });

    this.handleInterferers(document.body);

    this.$(document.body)
      .on('click', '.interferer-container > ion-button', async (e: any) => {
        const $ionButton: JQuery<HTMLElement> = this.$(e.currentTarget);
        const $container: JQuery<HTMLElement> = $ionButton.parent();
        const videoId: string = $container.attr('data-video-id');
        const attachment: Attachment = await this.attachmentService.getByID(videoId);

        if (attachment) {
          await this.openVideo(attachment);
        }
      });

    this.$(document.body)
      .on('click', '.interferer-container', async (e: any) => {
        if (this.platform.width() <= 480) {
          const $container = this.$(e.currentTarget);
          const videoId = $container.attr('data-video-id');
          const attachment = await this.attachmentService.getByID(videoId);

          if (attachment) {
            await this.openVideo(attachment);
          }
        }
      });
  }

  private async openVideo(video: Attachment): Promise<void> {
    await ModalHelper.closeAll();

    const modal: HTMLIonModalElement = await this.modalController.create({
      component: SingleVideoComponent,
      componentProps: { video },
      cssClass: 'modal-full-transparent'
    });

    await modal.present();
  }

  private handleInterferers(root: HTMLElement): void {
    this.$(root)
      .find('.interferer-container:not([data-ready])')
      .map((_, container: HTMLElement) => this.$(container))
      .each((_, $container: JQuery<HTMLElement>) => {
        const $ionContent: JQuery<HTMLElement> = $container.closest('ion-content');
        const $clone = $container.clone();

        $container
          .addClass('interferer-mobile')
          .attr('data-ready', 'true');

        $clone
          .addClass('interferer-desktop')
          .attr('data-ready', 'true')
          .prependTo($ionContent);
      });
  }
}