export class Progress {
  constructor(public total: number, public progress: number = 0) { }

  public increase(by: number = 1): Progress {
    this.progress += by;

    return this;
  }

  public decrease(by: number = 1): Progress {
    this.progress -= by;

    return this;
  }
}
