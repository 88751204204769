import { Listener } from './listener';
import { Platform } from '@ionic/angular';
import { AppInjector } from 'app/classes/app-injector/app-injector';

export class DocumentStyleListener implements Listener {
  private readonly STYLE_PROP_FULL_HEIGHT: string = '--rdi-full-height';
  private readonly STYLE_PROP_FULL_WIDTH: string = '--rdi-full-width';
  private readonly STYLE_PROP_HEADER_HEIGHT: string = '--rdi-header-height';
  private readonly STYLE_PROP_FOOTER_HEIGHT: string = '--rdi-footer-height';

  private readonly defaultHeaderHeight: number = 56;
  private readonly defaultFooterHeight: number = 164;

  private platform: Platform;

  constructor() {
    this.platform = AppInjector.prepare().get(Platform);
  }

  public start(): void {
    this.platform.resize.subscribe(() => this.updateStyleProperties());

    this.updateStyleProperties();
  }

  private updateStyleProperties(): void {
    this.setHeaderHeight(this.defaultHeaderHeight);
    this.setFooterHeight(this.defaultFooterHeight);
    this.setFullHeight(this.platform.height() - this.getHeaderHeight());
    this.setFullWidth(this.platform.width());
  }

  private setFullHeight(px: number): void {
    this.setDocumentProperty(this.STYLE_PROP_FULL_HEIGHT, `${px}px`);
  }

  private setFullWidth(px: number): void {
    this.setDocumentProperty(this.STYLE_PROP_FULL_WIDTH, `${px}px`);
  }

  private setHeaderHeight(px: number): void {
    this.setDocumentProperty(this.STYLE_PROP_HEADER_HEIGHT, `${px}px`);
  }

  private getHeaderHeight(): number {
    const height: number = parseInt(this.getDocumentProperty(this.STYLE_PROP_HEADER_HEIGHT));

    return (isNaN(height)) ? this.defaultHeaderHeight : height;
  }

  private setFooterHeight(px: number): void {
    this.setDocumentProperty(this.STYLE_PROP_FOOTER_HEIGHT, `${px}px`);
  }

  private setDocumentProperty(key: string, value: string): void {
    document.documentElement.style.setProperty(key, value);
  }

  private getDocumentProperty(key: string): string {
    return document.documentElement.style.getPropertyValue(key);
  }
}