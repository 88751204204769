import { AppInjector } from 'app/classes/app-injector/app-injector';
import { Router, NavigationEnd, Event } from '@angular/router';
import { StorageService, TranslatorService } from 'app/services';

import * as WaveSurfer from 'wavesurfer.js';
import * as jQuery from 'jquery';

import { generateId } from 'app/utils';
import { filter } from 'rxjs/operators';

import { Listener } from './listener';
import { AudioHelper } from 'app/classes/audio-helper/audio-helper';
import { ToastController } from '@ionic/angular';
import { RdiWindow } from 'app/interfaces/rdi';

type WavesurferItem = {
  $range: JQuery<HTMLElement>;
  wavesurfer: any;
};

declare var window: RdiWindow;

export class WavesurferListener implements Listener {
  private $: JQueryStatic = jQuery;
  private router: Router;
  private storageService: StorageService;
  private toastController: ToastController;
  private translator: TranslatorService;
  private readonly wavesurfers: Map<string, WavesurferItem> = new Map();

  constructor() {
    this.router = AppInjector.prepare().get(Router);
    this.storageService = AppInjector.prepare().get(StorageService);
    this.toastController = AppInjector.prepare().get(ToastController);
    this.translator = AppInjector.prepare().get(TranslatorService);
  }

  public async start(): Promise<void> {
    this.router.events
      .pipe(filter((e: Event) => e instanceof NavigationEnd))
      .subscribe(() => this.clearWavesurfers());

    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        await this.createWavesurfers(element);
        await this.createWavesurfers(document.body);
      } catch(err) {
        console.log(err);
      }
    });

    this.createWavesurfers();
  }

  private clearWavesurfers(): void {
    AudioHelper.stopAllAudios();

    Array.from(this.wavesurfers.values()).forEach(({ $range, wavesurfer }) => {
      wavesurfer.unAll();
      wavesurfer.destroy();

      $range.off().remove();
    });

    this.wavesurfers.clear();
  }

  private async createWavesurfers(root: HTMLElement = document.body): Promise<void> {
    const $audioElements = this.$(root).find('audio source[data-id]:not([data-ws-id])');

    $audioElements.attr('data-ws-id', '');

    for (const audio of $audioElements.toArray()) {
      const $audio = this.$(audio);
      const $parent = $audio.parents('[data-ws-color]');
      const color: string = $parent.attr('data-ws-color') || '#ffe500';

      await this.createWaveSurferAsync(audio, color);
    }
  }

  private createWaveSurferAsync(audio: HTMLElement, color: string): Promise<void> {
    return new Promise(async (resolve) => {
      try {
        await this.createWaveSurfer(audio, color);
      } catch(err) {
        console.log(err);
      }

      resolve();
    });
  }

  private async createWaveSurfer(audio: HTMLElement, color: string): Promise<void> {
    const id: string = generateId();
    const $source: JQuery<HTMLElement> = this.$(audio);
    const $audio: JQuery<HTMLElement> = $source.parent('audio');
    const $container: JQuery<HTMLElement> = this.$(audio).closest('.wavesurfer-container');
    const $pButton: JQuery<HTMLElement> = $container.find('.pButton');
    const $waveform: JQuery<HTMLElement> = $container.find('.waveform');
    const $control: JQuery<HTMLElement> = this.$('<div class="audio-control play"></div>');

    $waveform.attr({ id: `waveform-${id}` });

    const wavesurfer = (() => {
      const otone: boolean = $container.parent().hasClass('otone-overlay');
      const progressColor: string = $audio.parents('[data-ws-progress-color]').attr('data-ws-progress-color') || '#ffffff';

      return WaveSurfer.create({
        container: $waveform.get(0),
        waveColor: (otone) ? '#ffe500' : color,
        progressColor: progressColor,
        scrollParent: true,
        barWidth: (otone) ? 3 : 2,
        height: 50,
        barGap: (otone) ? 3: null,
        barRadius: (otone) ? 1 : 0,
        backend: 'MediaElement',
        xhr: {
          cache: 'default',
          mode: 'cors',
          method: 'GET',
          redirect: 'follow',
          headers: [
            { key: 'range', value: 'bytes=0-' }
          ]
        }
      });
    })();

    if (!$waveform.is('[data-no-border]')) {
      $waveform.css('border-color', color);
    }

    $pButton.css('border-color', color).append($control);
    $control.css('border-color', 'transparent transparent transparent ' + color );

    // Additional element RDI-268
    const $rangeItem: JQuery<HTMLElement> = this.$('<ion-item lines="none">');
    const $range: JQuery<HTMLElement> = this.$('<ion-range>');
    const $labelStart: JQuery<HTMLElement> = this.$('<ion-label slot="start">');
    const $labelEnd: JQuery<HTMLElement> = this.$('<ion-label slot="end">');

    $rangeItem.append($range);
    $rangeItem.attr({ 'color': 'default' }).css({ marginTop: '10px', display: 'none' });
    $labelStart.css({ color: '#fff' });
    $labelEnd.css({ color: '#fff' });
    $range.append([$labelStart, $labelEnd]);
    $range.css({
      'padding': '0',
      '--pin-background': color,
      '--bar-background-active': color
    });

    if ($container.parent().hasClass('otone-overlay')) {
      const $overlay: JQuery<HTMLElement> = $container.parent();

      $overlay.on('click', () => {
        if (wavesurfer.isPlaying()) {
          $overlay.animate({ opacity: 0 }, 400, () => wavesurfer.seekAndCenter(0));
        } else {
          $overlay.animate({ opacity: 1 });
        }

        wavesurfer.playPause();
      });
    } else if ($container.parent().is('[data-no-range]')) {
    } else {
      $waveform.append($rangeItem);
    }

    let rangeTouched: boolean = false;
    let wasPlayed: boolean = false;

    const updateRange = (time: number) => {
      $labelStart.text(this.formatTime(time));
      $range.attr({ value: time });
    };

    const updateWavesurfer = (time: number) => {
      wavesurfer.seekAndCenter(time / wavesurfer.getDuration());
    };

    const onRangeLeave = () => {
      rangeTouched = false;

      if (wasPlayed) {
        wavesurfer.play();
      }
    };

    const onRangeEnter = () => {
      rangeTouched = true;

      wavesurfer.pause();
    };

    let firstTimePlayed: boolean = true;

    const onTogglePlay = () => {
      wasPlayed = wavesurfer.isPlaying() || rangeTouched;

      if (firstTimePlayed) {
        firstTimePlayed = false;

        document.dispatchEvent(new CustomEvent('rdi::playAudio', {
          detail: {
            url: this.$(audio).attr('src')
          }
        }));
      }

      if (wavesurfer.isPlaying()) {
        $control.removeClass('play');
        $control.addClass('pause');
      } else {
        $control.removeClass('pause');
        $control.addClass('play');
      }
    };

    const onRangeChange = (e: any) => {
      if (rangeTouched) {
        const { value: time } = e.detail;

        updateRange(time);
        updateWavesurfer(time)
      }
    };

    const onAudioProcess = () => {
      if (!rangeTouched) updateRange(wavesurfer.getCurrentTime());
    };

    const onSeek = (time: number) => {
      updateRange(time * wavesurfer.getDuration())
    };

    const onPlayPause = (e) => {
      e.preventDefault();

      if (!wavesurfer.isPlaying()) AudioHelper.stopAllAudios();

      wavesurfer.playPause();
    }

    wavesurfer.on('ready', () => {
      $range.on('touchstart', onRangeEnter);
      $range.on('mousedown', onRangeEnter);
      $range.on('touchend', onRangeLeave);
      $range.on('mouseup', onRangeLeave);
      $range.on('mouseout', onRangeLeave);
      $range.on('ionChange', onRangeChange);
      wavesurfer.on('play', onTogglePlay);
      wavesurfer.on('pause', onTogglePlay);
      wavesurfer.on('audioprocess', onAudioProcess);
      wavesurfer.on('seek', onSeek);
      $pButton.on('click', onPlayPause);

      $range.attr({ max: wavesurfer.getDuration() });
      $labelEnd.text(this.formatTime(wavesurfer.getDuration()));

      updateRange(wavesurfer.getCurrentTime());

      $rangeItem.fadeIn();
    });


    wavesurfer.on('error', () => {
      $pButton.on('click', () => this.showToast('audio_could_not_be_loaded'));
      $range.on('touchstart', () => this.showToast('audio_could_not_be_loaded'));
    });

    const src: string = this.$(audio).attr('src');
    const url: string = await this.storageService.loadMediaSrc(src);

    wavesurfer.load(url);

    this.wavesurfers.set(id, { $range, wavesurfer });
  }

  private formatTime(time: number): string {
    const minutes: number = Math.floor(time / 60);
    const seconds: number = Math.floor(time - 60 * minutes);

    const minutesAsString: string = minutes.toString().padStart(2, '0');
    const secondsAsString: string = seconds.toString().padStart(2, '0');

    return `${minutesAsString}:${secondsAsString}`;
  }

  private async showToast(message: string, color: string = 'menucolor'): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message: this.translator.translate(message),
      showCloseButton: true,
      closeButtonText: this.translator.translate('close'),
      duration: 2000,
      color
    });

    await toast.present();

    return toast;
  }
}