const colorStreets = '#002C42';
const colorWater = '#003E59';
const colorEverythingElse = '#003E56';
const colorTextLabels = '#ffffff';
export const yellow = '#ffe500';
export const darkYellow = '#BBAF44';

export const browserStyles = [
  {
    featureType: 'all',

    stylers: [
      {
        color: colorEverythingElse,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: colorWater,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [{ color: colorTextLabels }],
  },
  {
    featureType: 'road',
    stylers: [{ color: colorStreets }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
];
// Used to hide the google tiles (if they have been downloaded)
export const mobileStyles = [
  {
    featureType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: colorWater,
      },
    ],
  },
  /*
  {
    featureType: 'geometry',

    stylers: [
      {
        visibility: 'on',
      },
      {
        color: colorWater,
      },
    ],
  },
  */
];

export const infoWindowStyle = {
  'text-align': 'center',
  padding: '20px',
  width: '200px',
  'border-color': yellow,
};
