import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/pages/home/home.module#HomePageModule',
  },
  {
    path: 'glossary',
    loadChildren: './pages/glossary/glossary.module#GlossaryPageModule',
  },
  {
    path: 'protagonist/:slug',
    loadChildren:
      './pages/singel-protagonist/singel-protagonist.module#SingelProtagonistPageModule',
  },
  {
    path: 'stories',
    loadChildren: './pages/stories/stories.module#StoriesPageModule',
  },
  {
    path: 'story/:slug/:quickOpen',
    loadChildren: './pages/story/story.module#StoryPageModule',
  },
  {
    path: 'story/:slug',
    loadChildren: './pages/story/story.module#StoryPageModule',
  },
  {
    path: 'map',
    loadChildren: './pages/map/map.module#MapPageModule',
  },
  {
    path: 'p/:slug',
    loadChildren: './pages/single-page/single-page.module#SinglePagePageModule',
  },
  {
    path: 'space/:slug',
    loadChildren: './pages/single-space/single-space.module#SingleSpacePageModule',
  },
  {
    path: 'settings',
    loadChildren: './pages/settings/settings.module#SettingsPageModule'
  },
  {
    path: 'location/:slug',
    loadChildren: './pages/single-location/single-location.module#SingleLocationPageModule'
  },
  {
    path: 'route/:slug',
    loadChildren: './pages/single-route/single-route.module#SingleRoutePageModule'
  },
  {
    path: 'home',
    loadChildren: './pages/stele-home/stele-home.module#SteleHomePageModule'
  },
  {
    path: 'home/:slug',
    loadChildren: './pages/stele-home/stele-page/stele-page.module#StelePagePageModule'
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
