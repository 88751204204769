import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { MapComponent } from 'app/components/map/map.component';
import { StoriesComponent } from 'app/components/stories/stories.component';
import { ProtagonistsListComponent } from 'app/components/protagonists-list/protagonists-list.component';
import { NavbuttonComponent } from 'app/components/navbutton/navbutton.component';
import { SpaceListComponent } from 'app/components/space-list/space-list.component';
import { StorytellerOverlayComponent } from 'app/components/storyteller-overlay/storyteller-overlay.component';
import { ImageViewerComponent } from 'app/components/image-viewer/image-viewer.component';
import { PassLockComponent } from 'app/components/pass-lock/pass-lock.component';
import { AdminConfigComponent } from 'app/components/admin-config/admin-config.component';
import { SpaceMapMenuComponent } from 'app/components/space-map-menu/space-map-menu.component';
import { AppBannerComponent } from 'app/components/app-banner/app-banner.component';
import { LocationNotificationComponent } from 'app/components/location-notification/location-notification.component';
import { LogoFooterComponent } from 'app/components/logo-footer/logo-footer.component';
import { HeaderTitleComponent } from 'app/components/header-title/header-title.component';
import { LocationListComponent } from 'app/components/location-list/location-list.component';
import { BackButtonComponent } from 'app/components/back-button/back-button.component';
import { LoadingIndicatorComponent } from 'app/components/loading-indicator/loading-indicator.component';
import { SingleVideoComponent } from 'app/components/modals/single-video/single-video.component';

import { PipesModule } from 'app/pipes.module';

const components: any[] = [
  SpaceMapMenuComponent,
  MapComponent,
  StoriesComponent,
  ProtagonistsListComponent,
  NavbuttonComponent,
  SpaceListComponent,
  StorytellerOverlayComponent,
  ImageViewerComponent,
  PassLockComponent,
  AdminConfigComponent,
  AppBannerComponent,
  LocationNotificationComponent,
  LogoFooterComponent,
  HeaderTitleComponent,
  LocationListComponent,
  BackButtonComponent,
  LoadingIndicatorComponent,
  SingleVideoComponent,
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, PipesModule],
  declarations: components,
  exports: components,
  entryComponents: components,
})
export class ComponentsModule {}
