import { Injectable } from '@angular/core';
import {
  Location,
  Protagonist,
  Route,
  Space,
  Story,
} from 'app/interfaces/api/types';
import { StorageService } from 'app/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceService {
  constructor(private storage: StorageService) {}

  public getAll(): Promise<Space[]> {
    return this.storage.getSpaces();
  }

  public async getByID(id: number | string): Promise<Space | undefined> {
    return (await this.getAll()).find((s: Space) => s.id == id);
  }

  public async getBySlug(slug: string): Promise<Space | undefined> {
    return (await this.getAll()).find((s: Space) => s.slug == slug);
  }

  /**
   * Helper functions
   */
  public async getProtagonists(space: Space): Promise<Protagonist[]> {
    const location_ids: number[] = [];
    const protagonist_ids: number[] = [];

    (await this.storage.getRoutes())
      .filter((r: Route) => r.space === space.id)
      .forEach((r: Route) =>
        r.locations.forEach((lId: number) => location_ids.push(lId)),
      );


    const locations: Location[] = (await this.storage.getLocations())
      .filter((l: Location) => location_ids.includes(l.id));

    for (const l of locations) {
      if (l.stories) {
        for (const story_id of l.stories) {
          const story = (await this.storage.getStories())
            .find((story: Story) => story.id === story_id);

          if (story && story.protagonist) {
            protagonist_ids.push(story.protagonist);
          }
        }
      }
    }

    return (await this.storage.getProtagonists())
      .filter((p: Protagonist) => protagonist_ids.includes(p.id));
  }
}
