import { Injectable } from '@angular/core';
import { File, Entry } from '@ionic-native/file/ngx';
import { Storage } from '@ionic/storage';
import { Attachment, Location, LocationType, Menu, Page, Protagonist, Route, Space, Story, Term, App } from 'app/interfaces/api/types';
import { AuthService } from 'app/services/auth/auth.service';
import { TranslatorService } from 'app/services/translator/translator.service';
import { Environment } from 'environments/environment';

import { arrayUnique, downloadFile, fetchUrl, unzipFile, Progress } from 'app/utils';
import { WebView } from '@ionic-native/ionic-webview/ngx';

export type FetchOptions = {
  endpoint: string;
  language: string;
  storage_key: string;
};
export type MediaDownloadResult = {
  downloads: number;
  failed: number
};
@Injectable({
  providedIn: 'root',
})

export class StorageService {
  private readonly LANGUAGES: string[] = Environment.getLanguages();
  private readonly API_BASE: string = Environment.getServerUrl() + '/wp-json/rdi/v2/';
  private readonly API_UPLOADS: string = Environment.getServerUploadUrl();
  private readonly DIRECTORIES: string[] = ['tiles', 'media', 'downloads'];
  private readonly httpHeaders = {
    'Authorization': `Bearer ${this.auth.getToken()}`,
    'Content-Type': 'application/json',
  };
  private isCaching: boolean = true;
  private offlineMediaFiles: Map<string, Entry> = new Map();
  private offlineMediaNeedsRefresh: boolean = true;
  private cachedAssets: Map<string, string> = new Map();

  constructor(
    private readonly auth: AuthService,
    private readonly storage: Storage,
    private readonly file: File,
    private readonly translator: TranslatorService,
    private readonly webView: WebView,
  ) { }

  private async fetchApi(opts: FetchOptions, fallback: any = []): Promise<any> {
    try {
      const { endpoint, language, storage_key } = opts;
      const url: string = `${this.API_BASE}${language}${endpoint}`;
      const data: any = await fetchUrl<any>(url, { }, this.httpHeaders);

      return (storage_key) ? await this.setCacheItem(opts, data) : data;
    } catch (err) {
      console.log(err);
      console.warn(`Failed to call API endpoint: ${opts.endpoint}`);

      return fallback;
    }
  }

  public async getCacheItem<T>(opts: FetchOptions): Promise<T> {
    const { language, storage_key } = opts;
    const key: string = `${language}-${storage_key}`;

    return (this.isCaching) ? await this.storage.get(key) : null;
  }

  public async setCacheItem<T>(opts: FetchOptions, data: T): Promise<T> {
    const { language, storage_key } = opts;
    const key: string = `${language}-${storage_key}`;

    await this.storage.set(key, data);

    return data;
  }

  public get mediaLocation(): string {
    return this.maybeEncodeURI(this.file.dataDirectory + 'media/');
  }

  public get tilesLocation(): string {
    return this.maybeEncodeURI(this.file.dataDirectory + 'tiles/');
  }

  public get downloadsLocation(): string {
    return this.maybeEncodeURI(this.file.dataDirectory + 'downloads/');
  }

  private async listDir(dir: string): Promise<Entry[]> {
    try {
      const entries: Entry[] = await this.file.listDir(this.file.dataDirectory, dir) || [];

      for (const entry of entries) {
        entry.name = this.maybeEncodeURI(entry.name);
      }

      return entries;
    } catch(err) {
      console.log(err);

      return [];
    }
  }

  public listMedia(): Promise<Entry[]> {
    return this.listDir('media');
  }

  public listTiles(): Promise<Entry[]> {
    return this.listDir('tiles');
  }

  public listDownloads(): Promise<Entry[]> {
    return this.listDir('downloads');
  }

  public async getProtagonists(language?: string): Promise<Protagonist[]> {
    const opts: FetchOptions = {
      storage_key: 'protagonists',
      endpoint: '/posts/protagonist',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getLocations(language?: string): Promise<Location[]> {
    const opts: FetchOptions = {
      storage_key: 'locations',
      endpoint: '/posts/location',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getLocationTypes(language?: string): Promise<LocationType[]> {
    const opts: FetchOptions = {
      storage_key: 'location-types',
      endpoint: '/posts/location-type',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getSpaces(language?: string): Promise<Space[]> {
    const opts: FetchOptions = {
      storage_key: 'spaces',
      endpoint: '/posts/space',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getRoutes(language?: string): Promise<Route[]> {
    const opts: FetchOptions = {
      storage_key: 'routes',
      endpoint: '/posts/route',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getStories(language?: string): Promise<Story[]> {
    const opts: FetchOptions = {
      storage_key: 'stories',
      endpoint: '/posts/story',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getTerms(language?: string): Promise<Term[]> {
    const opts: FetchOptions = {
      storage_key: 'terms',
      endpoint: '/posts/term',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getPages(language?: string): Promise<Page[]> {
    const opts: FetchOptions = {
      storage_key: 'pages',
      endpoint: '/pages',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getHomePage(language?: string): Promise<Page> {
    const opts: FetchOptions = {
      storage_key: 'homepage',
      endpoint: '/config/homepage',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getAttachments(language?: string): Promise<Attachment[]> {
    const opts: FetchOptions = {
      storage_key: 'attachments',
      endpoint: '/posts/attachment',
      language: language || this.translator.getLanguage(),
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getApps(language?: string): Promise<App[]> {
    const opts: FetchOptions = {
      storage_key: 'apps',
      endpoint: '/posts/app',
      language: language || this.translator.getLanguage()
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getAppMenu(language?: string): Promise<Menu> {
    const opts: FetchOptions = {
      storage_key: 'app-menu',
      endpoint: '/menu/app-menu',
      language: language || this.translator.getLanguage()
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async getStelenMenu(language?: string): Promise<Menu> {
    const opts: FetchOptions = {
      storage_key: 'stelen-menu',
      endpoint: '/menu/stelen-menu',
      language: language || this.translator.getLanguage()
    };

    return await this.getCacheItem(opts) || await this.fetchApi(opts);
  }

  public async downloadPosts(language: string, onProgress?: Function): Promise<boolean> {
    try {
      const promises: Promise<unknown>[] = [
        this.getProtagonists(language),
        this.getLocations(language),
        this.getLocationTypes(language),
        this.getSpaces(language),
        this.getRoutes(language),
        this.getStories(language),
        this.getTerms(language),
        this.getPages(language),
        this.getHomePage(language),
        this.getAttachments(language),
        this.getApps(language),
        this.getAppMenu(language),
        this.getStelenMenu(language)
      ];

      if (onProgress) {
        const progress: Progress = new Progress(promises.length);

        onProgress(progress);

        promises.forEach((p: Promise<unknown>) => p.finally(() => onProgress(progress.increase())));
      }

      await Promise.all(promises);

      return true;
    } catch (err) {
      console.error(err);
      console.warn('Could not prepare offline posts');

      return false;
    }
  }

  public async getSpaceMedia(space: Space, onProgress?: Function): Promise<string[]> {
    const progress: Progress = new Progress(this.LANGUAGES.length);
    let media: string[] = [];

    onProgress && onProgress(progress);

    /** Collect all media from all spaces's languages */
    for (const language of this.LANGUAGES) {
      const s: Space = (await this.getSpaces(language)).find(({ slug }) => slug === space.slug);

      if (s && Array.isArray(s.media_package)) {
        media = media.concat(s.media_package);
      }

      onProgress && onProgress(progress.increase());
    }

    return arrayUnique<string>(media);
  }

  public async getPageMedia(page: Page, onProgress?: Function): Promise<string[]> {
    const progress: Progress = new Progress(this.LANGUAGES.length);
    let media: string[] = [];

    onProgress && onProgress(progress);

    /** Collect all media from all pages's languages */
    for (const language of this.LANGUAGES) {
      const p: Page = (await this.getPages(language)).find(({ slug }) => slug === page.slug);

      if (p && Array.isArray(p.media_package)) {
        media = media.concat(p.media_package);
      }

      onProgress && onProgress(progress.increase());
    }

    return arrayUnique<string>(media);
  }

  private async downloadSingleMedia(url: string): Promise<boolean> {
    const filename: string = this.maybeEncodeURI(url.split('/').pop());

    try {
      const exists: boolean = await this.file.checkFile(this.mediaLocation, filename);

      if (exists) {
        return true;
      } else {
        throw new Error(`File does not exist: ${filename}`);
      }
    } catch(err) {
      return await downloadFile(url, this.mediaLocation + filename)
        .then(() => true)
        .catch(async (err) => {
          console.log(err);

          await this.deleteSingleMedia(filename);

          return false;
        });
    }
  }

  private async deleteSingleMedia(filename: string): Promise<boolean> {
    try {
      await this.file.removeFile(this.mediaLocation, filename);

      return true;
    } catch(err) {
      return false;
    }
  }

  public async downloadMedia(media: string[], onProgress?: Function): Promise<MediaDownloadResult> {
    const progress: Progress = new Progress(media.length);
    const results: MediaDownloadResult = {
      failed: 0,
      downloads: 0
    };

    onProgress && onProgress(progress);

    for (const url of media) {
      const downloaded: boolean = await this.downloadSingleMedia(url);

      if (downloaded) {
        results.downloads++;
      } else {
        results.failed++;
      }

      onProgress && onProgress(progress.increase());
    }

    this.offlineMediaNeedsRefresh = true;

    await this.loadOfflineMedia();

    return results;
  }

  public async downloadSpaceMap(space: Space): Promise<void> {
    const filename: string = this.maybeEncodeURI(`${space.slug}.zip`);
    const url: string = this.maybeEncodeURI(this.API_UPLOADS + `tiles/spaces/${filename}`);

    await downloadFile(url, this.downloadsLocation + filename);

    /**
     * Tiles are unzipped directory into the data directory
     * because the extracted directory will be "tiles".
     * This avoids the path "tiles/tiles/z/x/y.png".
     */
    const unzipped: boolean = await unzipFile(this.downloadsLocation + filename, this.file.dataDirectory);

    await this.setSpaceMapOffline(space, unzipped);

    if (!unzipped) {
      throw new Error('Failed to download')
    }
  }

  public async getSpaceMapOffline(space: Space): Promise<boolean> {
    return await this.storage.get(`SpaceMap::${space.slug}`);
  }

  public async setSpaceMapOffline(space: Space, isOffline: boolean): Promise<void> {
    await this.storage.set(`SpaceMap::${space.slug}`, isOffline);
  }

  public async getPageMediaOffline(page: Page): Promise<boolean> {
    return await this.storage.get(`PageMedia::${page.slug}`);
  }

  public async setPageMediaOffline(page: Page, isOffline: boolean): Promise<void> {
    await this.storage.set(`PageMedia::${page.slug}`, isOffline);
  }

  public async getSpaceMediaOffline(space: Space): Promise<boolean> {
    return await this.storage.get(`SpaceMedia::${space.slug}`);
  }

  public async setSpaceMediaOffline(space: Space, isOffline: boolean): Promise<void> {
    await this.storage.set(`SpaceMedia::${space.slug}`, isOffline);
  }

  public async clear(): Promise<void> {
    localStorage.clear();

    await this.storage.clear();
  }

  public async ready(): Promise<void> {
    await this.storage.ready();
    await this.initDirectories();
    await this.loadOfflineMedia();
  }

  private async initDirectories(): Promise<void> {
    for (const dir of this.DIRECTORIES) {
      try {
        await this.file.createDir(this.file.dataDirectory, dir, false);
      } catch(err) { }
    }
  }

  public async loadOfflineMedia(): Promise<void> {
    if (this.offlineMediaNeedsRefresh) {
      this.offlineMediaFiles.clear();

      for (const entry of await this.listMedia()) {
        this.offlineMediaFiles.set(entry.name, entry);
      }

      this.offlineMediaNeedsRefresh = false;
    }
  }

  /**
   * Converts a Remote URL to a local URL if the file is downloaded.
   */
  public convertMediaSrc(src: string): string {
    const filename: string = this.maybeEncodeURI(src.split('/').pop());

    if (this.offlineMediaFiles.has(filename)) {
      const url: string = this.offlineMediaFiles.get(filename).toURL();

      return this.webView.convertFileSrc(url);
    } else {
      return src;
    }
  }

  /**
   * Converts a Remote URL to a data URL if the file is downloaded.
   */
  public async loadMediaSrc(src: string): Promise<string> {
    const filename: string = this.maybeEncodeURI(src.split('/').pop());

    if (this.offlineMediaFiles.has(filename)) {
      return await this.file
        .readAsDataURL(this.mediaLocation, filename)
        .catch(() => src);
    } else {
      return src;
    }
  }

  private convertImageToBase64(src: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const image = document.createElement('img');
      const ext = src.split('.').pop();

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;

        ctx.drawImage(image, 0, 0);

        resolve(canvas.toDataURL(`image/${ext}`));
      };

      image.onerror = reject;
      image.src = src;
    });
  }

  public async loadAsset(src: string): Promise<string> {
    if (!this.cachedAssets.has(src)) {
      const base64: string = await this.convertImageToBase64(src);

      this.cachedAssets.set(src, base64);
    }

    return this.cachedAssets.get(src);
  }

  /**
   * Deletes all directories and recreates them afterwards
   */
  public async renewDirectories(): Promise<void> {
    for (const dir of this.DIRECTORIES) {
      try {
        await this.file.removeRecursively(this.file.dataDirectory, dir);
      } catch(err) { }
    }

    await this.initDirectories();
  }

  public async withoutCache(f: Function): Promise<any> {
    let res: any;

    this.isCaching = false;

    try {
      res = await f();
    } catch(err) {
      console.log(err);
    }

    this.isCaching = true;

    return res;
  }

  public maybeEncodeURI(s: string): string {
    return (decodeURI(s) === s && encodeURI(s) !== s) ? encodeURI(s) : s;
  }
}
