import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import * as jQuery from 'jquery';

@Injectable({
  providedIn: 'root'
})

export class ActivityService {
  public readonly onInactive: Subject<void> = new Subject();

  private lastInteraction: number;
  private maxTimeout: number = 300000; // Timeout in milliseconds
  private events: string[] = [
    'click',
    'keydown',
    'keyup',
    'scroll',
    'tap',
    'touchstart',
    'touchend'
  ];

  constructor() {
    this.lastInteraction = Date.now();

    jQuery(document)
      .on(this.events.join(' '), () => this.lastInteraction = Date.now());

    setInterval(() => {
      const timePassed: number = Date.now() - this.lastInteraction;

      if (timePassed >= this.maxTimeout) {
        this.onInactive.next();

        this.lastInteraction = Date.now();
      }
    }, 1000);
  }
}
