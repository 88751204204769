import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Space } from 'app/interfaces/api/types';
import { SpaceService } from 'app/services';
import { AudioHelper } from 'app/classes/audio-helper/audio-helper';

import spacePositions from './spacePositions';

interface MapMenuItem {
  space: Space;
  active: boolean;
  dimmed: boolean;
  position: { x: number; y: number }; // relative values, between 0.0 and 1.0
}

@Component({
  selector: 'space-map-menu',
  templateUrl: './space-map-menu.component.html',
  styleUrls: ['./space-map-menu.component.scss'],
})
export class SpaceMapMenuComponent implements OnInit {
  private selectedSpace: Space | null;
  public items: MapMenuItem[] = [];

  constructor(private spaceService: SpaceService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    const spaces: Space[] = await this.spaceService.getAll();

    for (const space of spaces) {
      const position = this.getPositionBySpace(space);
      this.items.push({
        space,
        active: false,
        dimmed: false,
        position,
      });
    }
  }

  public openPopup(event: any | null, space: Space | null): void {
    if (event) event.stopPropagation();

    if (!space || !this.selectedSpace || (space.id !== this.selectedSpace.id)) {
      AudioHelper.stopAllAudios();
    }

    this.selectedSpace = space;

    this.items.forEach(item => {
      item.active = space !== null && item.space.id === space.id;
      item.dimmed = space !== null && item.space.id !== space.id;
    });
  }

  public goToSpace(space: Space): void {
    this.router.navigateByUrl(`/space/${space.slug}`);
  }

  private getPositionBySpace(space: Space): { x: number; y: number } {
    return spacePositions[space.id] || { x: 0, y: 0 };
  }
}
