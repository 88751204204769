import * as jQuery from 'jquery';

import { Listener } from './listener';

export class AccordionListener implements Listener {
  private $: JQueryStatic = jQuery;

  public start(): void {
    this.$(document.body).on('click', '.accordion .abutton', (e: any) => {
      const $accordion: JQuery<HTMLElement> = this.$(e.currentTarget).parent('.accordion');

      this.toggleAccordion($accordion);
    });
  }

  private toggleAccordion($accordion: JQuery<HTMLElement>): void {
    if ($accordion.is('[data-active]')) {
      this.closeAccordion($accordion);
    } else {
      this.openAccordion($accordion);
    }
  }

  private closeAccordion($accordion: JQuery<HTMLElement>): void {
    const $title: JQuery<HTMLElement> = $accordion.find('.atitle');
    const $content: JQuery<HTMLElement> = $accordion.find('.acontent');
    const $button: JQuery<HTMLElement> = $accordion.find('.abutton');

    $content.slideUp(400, () => {
      $button.insertAfter($title);
      $button.find('ion-icon').attr('name', 'arrow-down');
      $accordion.removeAttr('data-active');
    });
  }

  private openAccordion($accordion: JQuery<HTMLElement>): void {
    const $content: JQuery<HTMLElement> = $accordion.find('.acontent');
    const $button: JQuery<HTMLElement> = $accordion.find('.abutton');

    $button.insertAfter($content);

    $content.css({ display: 'block' }).hide().slideDown(400, () => {
      $button.find('ion-icon').attr('name', 'arrow-up');
      $accordion.attr('data-active', '');
    });
  }
}