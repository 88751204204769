export class Environment {
  public static getServerUrl(): string {
    return (Environment.isProduction())
      ? 'https://api.industriekultur.guide'
      : 'https://rdi.amicaldo.net';
  }

  public static getServerUploadUrl(): string {
    return Environment.getServerUrl() + '/wp-content/uploads/';
  }

  public static getAppPassword(): string {
    return '11332113';
  }

  public static getApiToken(): string {
    return [
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9',
      'eyJpZCI6MCwic3ViIjowLCJpYXQiOjE1NjIwNTg1NDUsIm5iZiI6MTU2MjA1ODU0NX0',
      'CJYDVTvqehiA_S8b9zAldKu19dtk9R8_tpFMVJ78UUE'
    ].join('.');
  }

  public static getDefaultProtagonistAvatar(): string {
    return '/assets/img/default_protagonist_avatar.svg';
  }

  public static getStorytellerAvatarDefault(): string {
    return '/assets/img/storyteller_avatar.svg';
  }

  public static getStorytellerAvatarRuhrort(): string {
    return '/assets/img/storyteller_avatar_ruhrort.svg';
  }

  public static isProduction(): boolean {
    return localStorage.getItem('rdi_environment') !== 'development';
  }

  public static isDevelopment(): boolean {
    return localStorage.getItem('rdi_environment') === 'development';
  }

  public static initProduction(): void {
    localStorage.removeItem('rdi_environment');

    window.location.reload();
  }

  public static initDevelopment(): void {
    localStorage.setItem('rdi_environment', 'development');

    window.location.reload();
  }

  public static getLanguages(): string[] {
    return ['de', 'en'];
  }

  public static getAppVersion(): string {
    return 'v1.4.2';
  }
}
