import { GoogleMap } from '@ionic-native/google-maps';

export class MapTilehelper {
  private tiles: Set<string> = new Set<string>();

  constructor(private map: GoogleMap) { }

  public getTile(x: number, y: number, z: number, prefix: string = ''): string {
    return `${prefix}${z}/${x}/${y}.png`;
  }

  /**
   * Zoom helpers
   */
  public getZoom(): number {
    return this.map.getCameraZoom();
  }

  public setZoom(zoom: number): void {
    this.map.setCameraZoom(zoom);
  }

  public zoomIn(): void {
    this.setZoom(this.getZoom() + 1);
  }

  public zoomOut(): void {
    this.setZoom(this.getZoom() - 1);
  }

  /**
   * Just collect tiles, don't return anything
   */
  public collectTile(x: number, y: number, z: number, prefix: string = ''): null {
    this.tiles.add(this.getTile(x, y, z, prefix));

    return null;
  }

  public dumpTiles(): string[] {
    return Array.from(this.tiles);
  }

  public clearTiles(): void {
    this.tiles.clear();
  }
}