import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StoryService } from 'app/services';

import { Story } from 'app/interfaces/api/types';

@Component({
  selector: 'stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
})
export class StoriesComponent implements OnInit {
  public stories: Story[] = [];

  constructor(
    private storyService: StoryService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.stories = await this.storyService.getAll();
  }

  goToStory(story: Story): void {
    this.router.navigateByUrl(`/story/${story.slug}`);
  }
}
