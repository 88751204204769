import { AppInjector } from 'app/classes/app-injector/app-injector';
import { StorageService } from 'app/services';
import * as jQuery from 'jquery';

import { Listener } from './listener';

import { timeoutAsync } from 'app/utils/timeout';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;

let storageService: StorageService;

export class SvgExtractorListener implements Listener {
  private $: JQueryStatic = jQuery;

  constructor() {
    storageService = AppInjector.prepare().get(StorageService);
  }

  public start(): void {
    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        await timeoutAsync(250);
        await createSvgs(element);
        await createSvgs(document.body);
      } catch(err) {
        console.log(err);
      }
    });

    createSvgs(document.body);
  }
}

async function createSvgs(e: any): Promise<void> {
  const $elements: JQuery<HTMLElement> = jQuery(e).find('img.svg:not([data-svg-fixed])');
  const promises = $elements.toArray().map((element) => createSingleSvg(element));

  await Promise.all(promises);
}

async function createSingleSvg(element: HTMLElement, count: number = 0): Promise<void> {
  const $element: JQuery<HTMLElement> = jQuery(element);
  const url: string = $element.attr('src') || '';

  if (url.startsWith('/') || url.startsWith('assets')) {
    return;
  }

  /**
   * If the async safe-html pipe is not yet finished,
   * the src of the element is "null". We try it again
   * after 100ms with a maximum of 10 attempts.
   */
  if ((!url || url == 'null') && count <= 10) {
    await timeoutAsync(100);

    await createSingleSvg(element, ++count);
  } else {
    if ($element.parents('.story-page').length || $element.parents('.single-term-page').length) {
      await fixRemoteUrlWithCircle(element, url);
    } else {
      await fixRemoteUrl(element, url);
    }

    $element.attr('data-svg-fixed', '');
    $element.show();
  }
}

function fixRemoteUrl(element: HTMLElement, url: string): Promise<void> {
  return new Promise((resolve) => {
    url = storageService.convertMediaSrc(url);

    jQuery.get(url, (res) => {
      // Get the SVG tag, ignore the rest
      const $svg = jQuery(res).find('svg');
      let data = $svg.html();

      /**
       * to display circle reference is made by #SVGID_2 so
       * we need unique ID because we can have multiple per document...
       *
       */
      data = data.replace(new RegExp('SVGID_1_', 'g'), 'svgid1' + Date.now());
      data = data.replace(new RegExp('SVGID_2_', 'g'), 'svgid2' + Date.now());
      data = data.replace(new RegExp('SVGID_3_', 'g'), 'svgid3' + Date.now());
      data = data.replace(new RegExp('SVGID_4_', 'g'), 'svgid4' + Date.now());

      $svg.html(data);
      // $svg.find('circle:first').attr('r', 500);
      // $svg.find('g:nth-of-type(2)').remove();

      if ($svg.length > 0) jQuery(element).attr('src', 'data:image/svg+xml;base64,' + window.btoa($svg.get(0).outerHTML));

      jQuery(element).attr('data-svg-ready', '');

      resolve();
    }, 'xml').fail(() => resolve());
  });
}

function fixRemoteUrlWithCircle(element: HTMLElement, url: string): Promise<void> {
  return new Promise((resolve) => {
    url = storageService.convertMediaSrc(url);

    jQuery.get(url, (res) => {
      // Get the SVG tag, ignore the rest
      const $svg = jQuery(res).find('svg');
      let data = $svg.html();

      data = data.replace(
        new RegExp('.st0{', 'g'),
        '.st0{-webkit-clip-path:url(#SVGID_2_);',
      );

      data = data.replace(
        new RegExp('.st5{', 'g'),
        '.st5{-webkit-clip-path:url(#SVGID_4_);',
      );

      /**
       * to display circle reference is made by #SVGID_2 so
       * we need unique ID because we can have multiple per document...
       *
       */
      data = data.replace(new RegExp('SVGID_1_', 'g'), 'svgid1' + Date.now());
      data = data.replace(new RegExp('SVGID_2_', 'g'), 'svgid2' + Date.now());
      data = data.replace(new RegExp('SVGID_3_', 'g'), 'svgid3' + Date.now());
      data = data.replace(new RegExp('SVGID_4_', 'g'), 'svgid4' + Date.now());

      $svg.html(data);

      $svg.find('circle:first').attr({
        cx: 150,
        cy: 200,
        r: 200
      });

      if ($svg.length > 0) jQuery(element).attr('src', 'data:image/svg+xml;base64,' + window.btoa($svg.get(0).outerHTML));

      jQuery(element).attr('data-svg-ready', '');

      resolve();
    }, 'xml').fail(() => resolve());
  });
}
