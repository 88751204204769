import { Injectable } from '@angular/core';
import { Environment } from 'environments/environment';
import { Subject } from 'rxjs';
import translations from 'app/translations';

type Dictionary = {
  [key: string]: {
    [key: string]: string
  }
};

@Injectable({
  providedIn: 'root'
})

export class TranslatorService {
  public readonly languageChange: Subject<void> = new Subject();
  private readonly availableLanguages: string[] = Environment.getLanguages();
  private readonly translations: Dictionary = translations;
  private readonly defaultLanguage: string = 'de';
  private currentLanguage: string;

  constructor() {
    this.loadLanguage();
  }

  private loadLanguage(): void {
    const language: string = localStorage.getItem('translator::language');

    if (this.isValidLanguage(language)) {
      this.setLanguage(language);
    } else {
      this.setLanguage(this.defaultLanguage);
    }
  }

  public isValidLanguage(language: string): boolean {
    return this.availableLanguages.includes(language);
  }

  public setLanguage(language: string): void {
    if (this.isValidLanguage(language)) {
      if (language !== this.currentLanguage) {
        localStorage.setItem('translator::language', language);

        this.currentLanguage = language;

        this.languageChange.next();
      }
    } else {
      console.warn(`Tried to set invalid language: ${language}`);
    }
  }

  public getLanguage(): string {
    return this.currentLanguage;
  }

  public translate(key: string): string {
    if (
      this.translations &&
      this.translations[this.currentLanguage] &&
      this.translations[this.currentLanguage][key]
    ) {
      return this.translations[this.currentLanguage][key];
    }

    return key;
  }
}
