import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Attachment } from 'app/interfaces/api/types';

@Component({
  selector: 'app-single-video',
  templateUrl: './single-video.component.html',
  styleUrls: ['./single-video.component.scss'],
})
export class SingleVideoComponent implements OnInit, AfterViewInit {
  @ViewChild('containerElement', { read: ElementRef, static: false })
  private containerElement: ElementRef;

  @ViewChild('videoElement', { read: ElementRef, static: false })
  private videoElement: ElementRef;

  @Input() public video: Attachment;

  public height: number;
  public width: number;

  public didLoad: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.videoElement.nativeElement.onloadedmetadata = () => {
      const { offsetHeight, offsetWidth } = this.videoElement.nativeElement;

      this.containerElement.nativeElement.parentElement.style.height = `${offsetHeight}px`;
      this.containerElement.nativeElement.parentElement.style.width = `${offsetWidth}px`;
      this.containerElement.nativeElement.parentElement.style.margin = 'auto';
    };

    this.videoElement.nativeElement.oncanplay = () => this.didLoad = true;
  }
}
