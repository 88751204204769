import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'navbutton',
    templateUrl: './navbutton.component.html',
    styleUrls: ['./navbutton.component.scss'],
})

export class NavbuttonComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
