/**
 * Mock class if the kiosk mode plugin is not installed.
 * Rejects on any method call.
 */

export const KioskModeMock = (() => {
  const enable = () => Promise.reject('Plugin not installed');
  const disable = () => Promise.reject('Plugin not installed');
  const isEnabled = () => Promise.reject('Plugin not installed');
  const isLauncher = () => Promise.reject('Plugin not installed');
  const switchLauncher = () => Promise.reject('Plugin not installed');
  const enableAdb = () => Promise.reject('Plugin not installed');
  const disableAdb = () => Promise.reject('Plugin not installed');
  const isAdbEnabled = () => Promise.reject('Plugin not installed');

  return {
    enable,
    disable,
    isEnabled,
    isLauncher,
    switchLauncher,
    enableAdb,
    disableAdb,
    isAdbEnabled
  };
})();
