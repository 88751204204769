import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { TranslatePipe } from './services/translator/TranslatePipe';

@NgModule({
  declarations: [SafeHtmlPipe, TranslatePipe],
  imports: [],
  exports: [SafeHtmlPipe, TranslatePipe],
})
export class PipesModule {}
