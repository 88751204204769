import { AppInjector } from 'app/classes/app-injector/app-injector';
import { HTTP } from '@ionic-native/http/ngx';
import { FileEntry } from '@ionic-native/file/ngx';

let http: HTTP;

const needsUriEncoding = (s: string) => decodeURI(s) === s && encodeURI(s) !== s;

export const downloadFile = (url: string, destination: string): Promise<FileEntry> => {
  http = http || AppInjector.prepare().get(HTTP);
  url = needsUriEncoding(url) ? encodeURI(url) : url;
  destination = needsUriEncoding(destination) ? encodeURI(destination) : destination;

  http.setRequestTimeout(30);
  http.setFollowRedirect(true);

  return http.downloadFile(url, { }, { }, destination);
};