import { GoogleMap, GoogleMapsEvent, HtmlInfoWindow, ILatLng, ILatLngBounds, Marker, Polyline } from '@ionic-native/google-maps';
import { LocationType } from 'app/constants';
import { Location, Post, Route, Space } from 'app/interfaces/api/types';
import { ElementType, SpaceWithCenter } from 'app/interfaces/map';
import { getAngleFromPointToPoint, getDistance, getTrackCoordinates } from 'app/utils/map';
import { darkYellow, infoWindowStyle, yellow } from './map-style';
import { Subscription } from 'rxjs';
import { StoryChooser } from './storychooser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import UserLocator from './userlocator';

import { AppInjector } from 'app/classes/app-injector/app-injector';

import {
  ContextService,
  RouteService,
  SpaceService,
  StoryService,
  TranslatorService,
  LocationService,
  StorageService,
  LocationService as RdiLocationService
} from 'app/services';

// Keep track of all elements in order to show/hide them based on zoom levels
interface PostObjectWithElementAndInfoWindow {
  element: any;
  postObject: Post;
  auxKey?: string | number;
  infoWindow: HtmlInfoWindow | undefined;
}

let contextService: ContextService;
let routeService: RouteService;
let spaceService: SpaceService;
let storyService: StoryService;
let translatorService: TranslatorService;
let locationService: LocationService;
let storageService: StorageService;
let inAppBrowser: InAppBrowser;

export const initialMapZoom = 11;
export const minZoom = 9;
export const maxZoom = 17;
const selectedRoutePathWidth = 10;
const routePathWidth = 5;
const minDistanceBetweenArrows = 200

let subscription;
let zoomElements;
let visibleRouteSegment: any;

export function initMap() {
  subscription = new Subscription();

  contextService = AppInjector.prepare().get(ContextService);
  routeService = AppInjector.prepare().get(RouteService);
  spaceService = AppInjector.prepare().get(SpaceService);
  storyService = AppInjector.prepare().get(StoryService);
  translatorService = AppInjector.prepare().get(TranslatorService);
  locationService = AppInjector.prepare().get(LocationService);
  storageService = AppInjector.prepare().get(StorageService);
  inAppBrowser = AppInjector.prepare().get(InAppBrowser);
}

export function initZoomElements() {
  zoomElements = {
    [ElementType.NAVIGATION]: {
      min: minZoom,
      max: 18,
      elements: [],
    },
    [ElementType.GROUND_OVERLAY]: {
      min: minZoom,
      max: 18,
      elements: [],
    },
    [ElementType.SPACE]: {
      min: minZoom,
      max: 13,
      elements: [],
    },
    [ElementType.LOCATION]: {
      min: 13.01,
      max: 18,
      elements: [],
    },
    [ElementType.ROUTE_PATH]: {
      min: 13.01,
      max: 18,
      elements: [],
    },
    [ElementType.HIGHLIGHT_ROUTE_SEGMENT]: {
      min: 13.01,
      max: 18,
      elements: [],
    },
    [ElementType.ROUTE_ARROW]: {
      min: 13.01,
      max: 18,
      elements: [],
    },
  };
}

export function getElementsAndPostObjectsByType(
  type: ElementType,
): PostObjectWithElementAndInfoWindow[] {
  if (!zoomElements[type]) {
    throw new Error('element type not registered.');
  }
  return zoomElements[type].elements;
}

export function getElementsByType(type: ElementType) {
  if (!zoomElements[type]) {
    throw new Error('element type not registered.');
  }
  return zoomElements[type].elements.map(({ element }) => element);
}

export function getPostObjectsByType(type: ElementType) {
  if (!zoomElements[type]) {
    throw new Error('element type not registered.');
  }
  return zoomElements[type].elements.map(({ postObject }) => postObject);
}

function registerElement(type: ElementType, element: any, postObject?: Post, auxKey?: string | number): {element: any, postObject: Post | undefined, auxKey?: string | number} {
  const elementAndPostObject = { element, postObject, auxKey };
  zoomElements[type].elements.push(elementAndPostObject);
  return elementAndPostObject;
}

export function showElementsBasedOnZoom(zoom: number) {
  for (const type of Object.keys(zoomElements)) {
    if (type !== ElementType.HIGHLIGHT_ROUTE_SEGMENT) {
      for (const { element } of zoomElements[type].elements) {
        element.setVisible(
          zoom >= zoomElements[type].min && zoom <= zoomElements[type].max,
        );
      }
    } else {
      if (zoom >= zoomElements[type].min && zoom <= zoomElements[type].max) {
        for (const { element } of zoomElements[type].elements) {
          if (element === visibleRouteSegment) {
            element.setVisible(true);
          } else {
            element.setVisible(false);
          }
        }

      } else {
        for (const { element } of zoomElements[type].elements) {
          if (element === visibleRouteSegment) {
            visibleRouteSegment = element;
          }
          element.setVisible(false);
        }
      }
    }
  }
}

export async function registerRoutePath(route: Route, map: GoogleMap) {
  const points: ILatLng[] = getTrackCoordinates(route);
  const locationPoints: { point: ILatLng, location: Location }[] = [];

  if (points.length === 0) {
    return;
  }
  // Draw the whole route (yellow line)
  const line = await map.addPolyline({
    visible: false,
    points: points,
    color: darkYellow,
    width: routePathWidth,
    geodesic: false,
  });
  line.setZIndex(10);
  registerElement(ElementType.ROUTE_PATH, line, route);

  // Find closest points (on gpx routes) to locations and stack
  // them together with the following points (until the next location)
  // on the locationPoints stack. This produce tuples of
  // Location <-> Route Segments (containing the next points)
  //
  // Location A (contains the next 3 points until Location B)
  // *
  // *
  // *
  // Location B (contains the next 2 Points until Location C)
  // *
  // *
  // Location C .....

  // First: search for closest points to locations on gpx track
  const locations: Location[] = [];
  for (const lId of route.locations) {
    locations.push(await locationService.getByID(lId));
  }
  const stationLocations = locations.filter(l => l.type_slug === LocationType.STATION);
  for (let i = 0; i < stationLocations.length - 1; i++) {
    const location = stationLocations[i];
    const nextLocation = stationLocations[i + 1];

    let shortestDistance = null;
    let shortestDistancePoint = null;

    for (let j = 0; j < points.length; j++) {
      const point = points[j];
      const distance = getDistance(point, nextLocation.geolocations[0]);
      if (shortestDistance === null || (distance < shortestDistance)) {
        shortestDistance = distance;
        shortestDistancePoint = point;
      }
    }
    locationPoints.push({ point: shortestDistancePoint, location });
  }

  // Second: put them into route segments
  const routeSegments: { points: ILatLng[], location: Location }[] = [];
  let lastIndex = 0;
  for (let i = 0; i < locationPoints.length; i++) {
    const locationPoint = locationPoints[i];
    const routePoints: ILatLng[] = [];
    for (let j = lastIndex; j < points.length; j++) {
      const p = points[j];
      routePoints.push({ lat: p.lat, lng: p.lng });
      if (p.lat === locationPoint.point.lat && p.lng === locationPoint.point.lng) {
        routeSegments.push({ points: routePoints, location: locationPoint.location })
        lastIndex = j;
        break;
      }
    }
  }

  // Finally: draw the segments
  for (const segment of routeSegments) {
    const lineSegment = await map.addPolyline({
      visible: false,
      points: segment.points,
      color: '#ffffff',
      width: 12,
      geodesic: false,
    });
    lineSegment.setZIndex(10);
    registerElement(ElementType.HIGHLIGHT_ROUTE_SEGMENT, lineSegment, route, segment.location.id);
  }

  return routeSegments;
}

export async function registerSpaceMarker(
  spaceWithCenter: SpaceWithCenter,
  map: GoogleMap,
  storyChooser: StoryChooser,
  clickHandler: Function
) {
  const marker = await map.addMarker({
    visible: false,
    disableAutoPan: true,
    position: spaceWithCenter[1],
    icon: {
      url: await storageService.loadAsset('/assets/img/map/icon-circle.png'),
      size: { width: 40, height: 40 },
      anchor: [20, 20],
    },
  });
  const sub = marker.on(GoogleMapsEvent.MARKER_CLICK).subscribe(async () => {
    showInfoWindowForSpace(
      spaceWithCenter[0],
      clickHandler,
      map,
      storyChooser,
    );
    const route = await routeService.getByID(spaceWithCenter[0].routes[0]);
    contextService.setActiveRoute(route);
    updateRoutes(route, route.locations[0], map);
  });

  subscription.add(sub);

  registerElement(ElementType.SPACE, marker, spaceWithCenter[0]);
}

export async function registerLocationMarker(
  location: Location,
  map: GoogleMap,
  storyChooser: StoryChooser,
  clickHandler: Function
) {
  const index: number = (location.primaryRouteIndex !== undefined)
    ? location.primaryRouteIndex + 1
    : undefined;
  const iconUrl: string = (index === undefined)
    ? await storageService.loadAsset('/assets/img/map/icon-circle-fill-blue.png')
    : await storageService.loadAsset(`/assets/img/map/locations/Standortkreis_${index}.png`);

  const marker = await map.addMarker({
    visible: false,
    disableAutoPan: true,
    position: location.geolocations[0],
    opacity: 0.8,
    icon: {
      url:
        location.type_slug === LocationType.SIDE_KICK
          ? await storageService.loadAsset('/assets/img/map/icon-circle-white-fill-blue.png')
          : iconUrl,
      size: { width: 40, height: 40 },
      anchor: [20, 20],
    },
  });
  const sub = marker.on(GoogleMapsEvent.MARKER_CLICK).subscribe(async () => {
    showInfoWindowForLocation(
      location,
      storyChooser,
      clickHandler,
      map,
    );
    const route = await contextService.getActiveRoute();
    if (route) {
      updateRoutes(route, location.id, map);
    }
  });

  subscription.add(sub);

  registerElement(ElementType.LOCATION, marker, location);
}

export async function addRouteArrows(
  route: Route,
  map: GoogleMap,
  routeSegments: { points: ILatLng[], location: Location }[]
) {
  return;
  for (let i = 0; i < routeSegments.length -1; i++) {
    const routeSegment = routeSegments[i];
    // calculate an average angle from the first to all other segment-points
    let angle = 0;
    for (let j = 1; j < routeSegment.points.length; j++) {
      angle += getAngleFromPointToPoint(routeSegment.points[0], routeSegment.points[j]);
    }
    angle = angle / (routeSegment.points.length - 1);
    const marker = await map.addMarker({
      position: routeSegment.points[0],
      visible: false,
      opacity: 1,
      icon: {
        url: await storageService.loadAsset('/assets/img/map/icon-arrow-yellow.png'),
        size: { width: 20, height: 20 },
        anchor: [10, 10],
      }
    });
    marker.setRotation(angle);
    marker.setDisableAutoPan(true);
    registerElement(ElementType.ROUTE_ARROW, marker);
  }
}

export async function registerGroundOverlay(
  imageUrl: string,
  bounds: ILatLngBounds,
  map: GoogleMap,
) {
  const overlay = await map.addGroundOverlay({
    url: imageUrl,
    bounds: [bounds.northeast, bounds.southwest],
    opacity: 1,
    clickable: true,
  });
  registerElement(ElementType.GROUND_OVERLAY, overlay);
}

export function updateUserLocator(position: ILatLng) {
  const userLocator: UserLocator | null = zoomElements[ElementType.NAVIGATION]
    .elements.length
    ? zoomElements[ElementType.NAVIGATION].elements[0].element
    : null;
  if (userLocator) {
    userLocator.setPosition({ ...position });
  }
}

export async function initUserLocator(map: GoogleMap) {
  const userLocator = new UserLocator(map);
  await userLocator.init();
  registerElement(ElementType.NAVIGATION, userLocator);
}

export function stopUserLocator() {
  const userLocator: UserLocator | null = zoomElements[ElementType.NAVIGATION]
    .elements.length
    ? zoomElements[ElementType.NAVIGATION].elements[0].element
    : null;
  if (userLocator) {
    userLocator.setVisible(false);
  }
}

export function hideAllInfoWindows() {
  for (const element of [
    ...getElementsAndPostObjectsByType(ElementType.LOCATION),
    ...getElementsAndPostObjectsByType(ElementType.SPACE),
  ]) {
    if (element.infoWindow) {
      element.infoWindow.close();
    }
  }
}

async function showInfoWindowForSpace(
  space: Space,
  clickHandler: Function,
  map: GoogleMap,
  storyChooser: StoryChooser
) {
  const elementAndPostObject = getElementsAndPostObjectsByType(
    ElementType.SPACE,
  ).find(pair => pair.postObject.id === space.id);
  if (!elementAndPostObject) {
    throw new Error(`Element has not been registered for Space ${space.id}`);
  }
  const marker = elementAndPostObject.element;

  if (!marker) {
    throw new Error(`Marker has not been set for Space ${space.id}`);
  }

  const htmlInfoWindow = new HtmlInfoWindow();
  elementAndPostObject.infoWindow = htmlInfoWindow;
  htmlInfoWindow.setBackgroundColor(yellow);
  const htmlDiv = document.createElement('div');
  htmlDiv.innerHTML = `<div class="title">${space.name}</div><div class="subline">${space.short_description}</div>`;
  const gotoSpaceButton = document.createElement('ion-button');
  gotoSpaceButton.innerText = translatorService.translate('learn_more');
  gotoSpaceButton.setAttribute('color', 'hauptcolor');
  gotoSpaceButton.setAttribute('fill', 'outline');

  htmlDiv.appendChild(gotoSpaceButton);

  const targetButton = document.createElement('ion-button');
  targetButton.innerHTML = '<ion-icon name="locate"></ion-icon>\n';
  targetButton.setAttribute('color', 'hauptcolor');
  targetButton.setAttribute('fill', 'outline');
  htmlDiv.appendChild(targetButton);
  targetButton.addEventListener('click', async () => {
    const route = await routeService.getByID(space.routes[0]);
    let locationId: number;
    for (const lId of route.locations) {
      const location = await locationService.getByID(lId);
      if (location.type_slug === LocationType.STATION) {
        locationId = location.id;
        break;
      }
    }
    htmlInfoWindow.close();
    if (locationId) {
      const location = await locationService.getByID(locationId);
      zoomAndTargetLocation(
        location,
        map,
        storyChooser,
        clickHandler
      );
    }
  });

  htmlInfoWindow.setContent(htmlDiv, infoWindowStyle);

  gotoSpaceButton.addEventListener('click', () => {
    if (space.routes.length) {
      clickHandler();
    }
  });

  hideAllInfoWindows();

  htmlInfoWindow.open(marker);

  map.animateCamera({
    target: marker.getPosition(),
    duration: 500,
  });
}

export async function showInfoWindowForLocation(
  location: Location,
  storyChooser: StoryChooser,
  clickHandler: Function,
  map: GoogleMap
) {
  const elementAndPostObject = getElementsAndPostObjectsByType(
    ElementType.LOCATION,
  ).find(pair => pair.postObject.id === location.id);
  if (!elementAndPostObject) {
    throw new Error(
      `Element has not been registered for Location ${location.id}`,
    );
  }

  const marker = elementAndPostObject.element as Marker;

  if (!marker) {
    throw new Error(`Marker has not been set for Location ${location.id}`);
  }
  const htmlInfoWindow = new HtmlInfoWindow();
  elementAndPostObject.infoWindow = htmlInfoWindow;

  htmlInfoWindow.setBackgroundColor(yellow);
  const htmlDiv = document.createElement('div');
  const indizes = location.indexByRoute
    ? Object.values(location.indexByRoute).map(i => i + 1)
    : [];

  /* const joinedIndizes =
    location.type_slug === LocationType.STATION ? indizes.join('/') : null; */
  const highestIndex = location.type_slug === LocationType.STATION ? Math.max.apply(Math, indizes) : null;
  htmlDiv.innerHTML = `
      <div class="title">${
        highestIndex !== null
          ? `<span class="location-index">${highestIndex}</span>`
          : ''
      }${location.name}</div>
      <div class="subline">${location.short_description}</div>
    `;

  if (location.type_slug === LocationType.STATION) {
    const mapButton: any = document.createElement('ion-button');
    mapButton.innerText = translatorService.translate('navigate');
    mapButton.setAttribute('color', 'hauptcolor');
    mapButton.setAttribute('fill', 'outline');
    mapButton.setAttribute('mode', 'md');
    mapButton.addEventListener('click', openGoogleMaps);
    htmlDiv.appendChild(mapButton);

    const button = document.createElement('ion-button');
    button.innerText = translatorService.translate('learn_more');
    button.setAttribute('color', 'hauptcolor');
    button.setAttribute('fill', 'outline');
    button.setAttribute('mode', 'md');
    button.addEventListener('click', () => clickHandler());
    htmlDiv.appendChild(button);

    const route = await routeService.getByID(location.routes[0]);
    const space = await spaceService.getByID(route.space);

    storyChooser.setSpace(space);
    await storyChooser.setLocation(location);

    const stories = [];
    for (const storyId of location.stories) {
      stories.push(await storyService.getByID(storyId));
    }
    await storyChooser.setStories(stories);

    storyChooser.show();

    window.dispatchEvent(new Event('resize'));
  }

  htmlInfoWindow.setContent(htmlDiv, infoWindowStyle);

  hideAllInfoWindows();

  htmlInfoWindow.open(marker);

  map.animateCamera({
    target: marker.getPosition(),
    duration: 500,
  });
}

export function minimizeAllLocationMarkers() {
  getElementsByType(ElementType.LOCATION).forEach(async (element: Marker) => {
    element.setIcon({
      url: await storageService.loadAsset('/assets/img/map/icon-circle.png'),
      size: { width: 20, height: 20 },
    });

    element.setIconAnchor(10, 10);
  });
}

export function updateRoutes(currentRoute: Route, locationId: number | undefined, map: GoogleMap) {
  getElementsAndPostObjectsByType(ElementType.ROUTE_PATH).forEach(
    ({ element, postObject }) => {
      const routeLine = element as Polyline;
      if (postObject.id === currentRoute.id) {
        routeLine.setStrokeWidth(selectedRoutePathWidth);
        routeLine.setStrokeColor(yellow);
        routeLine.setZIndex(10);
      } else {
        routeLine.setStrokeWidth(routePathWidth);
        routeLine.setStrokeColor(darkYellow);
        routeLine.setZIndex(10);
      }
    },
  );
  const zoom = map.getCameraZoom();

  getElementsAndPostObjectsByType(ElementType.HIGHLIGHT_ROUTE_SEGMENT)
    .forEach(({ element, postObject, auxKey }) => {
      const routeLine = element as Polyline;
      if (auxKey && auxKey === locationId) {
        if (
          zoom >= zoomElements[ElementType.HIGHLIGHT_ROUTE_SEGMENT].min &&
          zoom <= zoomElements[ElementType.HIGHLIGHT_ROUTE_SEGMENT].max
        ) {
          routeLine.setVisible(true);
          visibleRouteSegment = routeLine;
          routeLine.setZIndex(100);
        }
      } else {
        routeLine.setVisible(false);
        routeLine.setZIndex(10);
      }
    });
}

export function zoomAndTargetLocation(
  location: Location,
  map: GoogleMap,
  storyChooser: StoryChooser,
  clickHandler: Function
) {
  map.setCameraTarget(location.geolocations[0]);
  const zoom = zoomElements[ElementType.LOCATION].min + 3;

  map.one(GoogleMapsEvent.CAMERA_MOVE_END).then(async () => {
    showInfoWindowForLocation(
      location,
      storyChooser,
      clickHandler,
      map
    );

    showElementsBasedOnZoom(zoom);
    const route = await routeService.getByID(location.routes[0]);
    updateRoutes(route, location.id, map);
  });

  map.setCameraZoom(zoom);
}

function openGoogleMaps() {
  const points: any[] = visibleRouteSegment
    .getPoints()
    .getArray();

  const url: URL = new URL('https://www.google.com');
  const dest: any = points.pop();
  const origin: any = points.shift();
  const step: number = Math.floor(points.length / 8) || 1;
  const waypoints: any[] = [];

  /* You can only use a total of 8 waypoints in Google Maps */
  for (let i: number = 0; i < points.length; i += step) {
    waypoints.push(points[i]);
  }

  url.pathname = '/maps/dir/';
  url.searchParams.set('api', '1');
  url.searchParams.set('travelmode', 'walking');
  url.searchParams.set('origin', `${origin.lat},${origin.lng}`);
  url.searchParams.set('destination', `${dest.lat},${dest.lng}`);
  url.searchParams.set(
    'waypoints',
    waypoints
      .map((p) => `${p.lat},${p.lng}`)
      .join('|')
  );

  inAppBrowser.create(url.toString(), '_system').show();
}

export function clearMapSubscriptions() {
  subscription.unsubscribe();
}