import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TranslatorService } from 'app/services';

import { Location } from 'app/interfaces/api/types';

@Component({
  selector: 'app-location-notification',
  templateUrl: './location-notification.component.html',
  styleUrls: ['./location-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LocationNotificationComponent implements OnInit, OnDestroy {
  @Input() location: Location;

  public language: string;

  constructor(
    private translator: TranslatorService,
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit(): void {
    this.language = this.translator.getLanguage();
  }

  ngOnDestroy(): void { }

  public dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
