import * as jQuery from 'jquery';
import * as BeerSlider from 'app/utils/BeerSlider';

import { Listener } from './listener';

import { AppInjector } from 'app/classes/app-injector/app-injector';
import { Router, NavigationEnd, Event } from '@angular/router';
import { generateId } from 'app/utils';
import { filter } from 'rxjs/operators';
import { RdiWindow } from 'app/interfaces/rdi';

declare var window: RdiWindow;
export class ImageComparisonSliderListener implements Listener {
  private $: JQueryStatic = jQuery;
  private sliders: Map<string, BeerSlider> = new Map();
  private router: Router;

  constructor() {
    this.router = AppInjector.prepare().get(Router);
  }

  public start(): void {
    window.rdi.listeners.push(async (element: HTMLElement) => {
      try {
        await this.createSliders(element);
        await this.createSliders(document.body);
      } catch(err) {
        console.log(err);
      }
    });

    this.createSliders(document.body);

    this.router.events
      .pipe(filter((e: Event) => e instanceof NavigationEnd))
      .subscribe(() => this.clearSliders());
  }

  private clearSliders(): void {
    Array.from(this.sliders.values())
      .map(({ element }) => element)
      .filter((element: HTMLElement) => element)
      .forEach((element: HTMLElement) => element.remove());

    this.sliders.clear();
  }

  private createSliders(root: HTMLElement): void {
    const $beerSliders = this.$(root).find('.beer-slider:not([data-ready]):not(.beer-ready');

    $beerSliders.attr('data-ready', 'true');

    $beerSliders.each((_, e: HTMLElement) => {
      const id: string = generateId();

      this.$(e).css({ display: 'block' }).attr({ id });

      this.sliders.set(id, new BeerSlider(e));
    });
  }
}